
import React from "react";
import { Menu, X } from "lucide-react";
import { Button } from "@/components/ui/button";

interface NavbarMenuButtonProps {
  isMenuOpen: boolean;
  toggleMenu: () => void;
}

const NavbarMenuButton = ({ isMenuOpen, toggleMenu }: NavbarMenuButtonProps) => {
  return (
    <Button 
      variant="ghost" 
      size="icon" 
      onClick={toggleMenu} 
      className={`text-foreground p-2 rounded-full ${isMenuOpen ? "bg-primary/10" : ""}`}
    >
      {isMenuOpen ? <X className="h-5 w-5" /> : <Menu className="h-5 w-5" />}
    </Button>
  );
};

export default NavbarMenuButton;
