
import { icons } from "./icons";
import type { ServiceType } from "./ServiceCard";

export const services: ServiceType[] = [
  {
    id: "ai-architecture",
    title: "AI System Architecture Design & Optimization",
    description: "Expert design and optimization of AI systems for specific business and technical requirements.",
    icon: icons.cpu,
    category: "tech",
    capabilities: [
      "End-to-end AI system design for specific business applications",
      "Scalable architecture planning for enterprise deployment",
      "Integration strategies with existing business systems",
      "Performance optimization of model inference pipelines",
      "Hardware-specific architecture optimization (CPU, GPU, TPU)"
    ]
  },
  {
    id: "custom-models",
    title: "Custom Model Development",
    description: "Development of specialized AI models tailored to your specific domain and data requirements.",
    icon: icons.brain,
    category: "research",
    capabilities: [
      "Custom neural network architectures for specific applications",
      "Transfer learning from pre-trained models to domain-specific tasks",
      "Multimodal learning systems that combine diverse data types",
      "Reinforcement learning systems for sequential decision problems",
      "Explainable AI implementations for regulated industries"
    ]
  },
  {
    id: "performance-optimization",
    title: "ML Pipeline Optimization",
    description: "Optimization of existing machine learning pipelines for improved performance, efficiency, and scalability.",
    icon: icons.network,
    category: "tech",
    capabilities: [
      "Performance bottleneck identification and resolution",
      "Data preprocessing pipeline optimization",
      "Model architecture refinement for improved accuracy-speed tradeoffs",
      "Training procedure optimization for faster convergence",
      "Deployment pipeline streamlining for CI/CD integration"
    ]
  },
  {
    id: "education",
    title: "AI Education & Training",
    description: "Specialized training programs and workshops for organizations looking to build AI capabilities.",
    icon: icons.graduationCap,
    category: "education",
    capabilities: [
      "Custom AI curriculum development for technical teams",
      "Hands-on workshops for practical AI implementation",
      "Executive education on AI strategy and governance",
      "Skill gap assessment and personalized learning paths",
      "Ongoing mentorship for AI practitioners"
    ]
  },
  {
    id: "explainable-ai",
    title: "Explainable AI Implementation",
    description: "Development of transparent AI systems with interpretable decision processes for regulated industries.",
    icon: icons.idea,
    category: "healthcare",
    capabilities: [
      "Implementation of inherently interpretable models",
      "Post-hoc explanation techniques for complex models",
      "Counterfactual explanation systems for decision understanding",
      "Visual explanation interfaces for non-technical users",
      "Compliance documentation for regulatory requirements"
    ]
  },
  {
    id: "data-strategy",
    title: "AI-Driven Data Strategy",
    description: "Development of comprehensive data strategies to maximize the value of AI initiatives.",
    icon: icons.database,
    category: "tech",
    capabilities: [
      "Data requirement planning for AI initiatives",
      "Data quality assessment and improvement roadmaps",
      "Data collection and annotation strategy development",
      "Data governance frameworks for AI applications",
      "Privacy-preserving data utilization strategies"
    ]
  },
  {
    id: "neurosymbolic-ai",
    title: "Neurosymbolic AI Solutions",
    description: "Integrating neural networks with symbolic reasoning for more explainable and data-efficient AI solutions.",
    icon: icons.flask,
    category: "research",
    capabilities: [
      "Hybrid AI system design combining neural and symbolic approaches",
      "Program synthesis for scientific discovery",
      "Automated theory generation from data",
      "Knowledge-based neural systems for domain-specific applications",
      "Explainable AI through symbolic reasoning"
    ]
  },
  {
    id: "healthcare-ai",
    title: "Healthcare AI Solutions",
    description: "Specialized AI solutions for healthcare, including digital phenotyping and mental health assessment.",
    icon: icons.activity,
    category: "healthcare",
    capabilities: [
      "Digital phenotyping for mental health assessment",
      "Mobile sensing for behavioral health monitoring",
      "Personalized medicine and treatment recommendation systems",
      "Clinical decision support with explainable outputs",
      "GLP-1 management systems with cultural context awareness"
    ]
  }
];
