
import React from 'react';
import { Helmet } from 'react-helmet-async';

interface MetaProps {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
  type?: string;
  twitterCard?: string;
  jsonLd?: object;
}

const Meta: React.FC<MetaProps> = ({ 
  title = "Dr. Omar Costilla Reyes | MIT CSAIL AI Scientist & Equ.care CEO",
  description = "Dr. Omar Costilla Reyes is an AI scientist at MIT CSAIL and CEO of Equ.care, developing innovative AI solutions for healthcare and scientific discovery.",
  image = "/lovable-uploads/ba7f970c-cb09-469e-9341-5ca1c833bbda.png",
  url = "https://omarcostilla.mit.edu",
  type = "website",
  twitterCard = "summary_large_image",
  jsonLd,
}) => {
  const fullUrl = url.startsWith('http') ? url : `https://omarcostilla.mit.edu${url}`;
  const fullImage = image.startsWith('http') ? image : `https://omarcostilla.mit.edu${image}`;

  return (
    <Helmet>
      {/* Basic metadata */}
      <title>{title}</title>
      <meta name="description" content={description} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content={type} />
      <meta property="og:url" content={fullUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={fullImage} />
      
      {/* Twitter */}
      <meta name="twitter:card" content={twitterCard} />
      <meta name="twitter:url" content={fullUrl} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={fullImage} />
      
      {/* Canonical URL */}
      <link rel="canonical" href={fullUrl} />
      
      {/* JSON-LD structured data */}
      {jsonLd && (
        <script type="application/ld+json">
          {JSON.stringify(jsonLd)}
        </script>
      )}
    </Helmet>
  );
};

export default Meta;
