
import React, { useState, useEffect } from "react";
import { useIsMobile } from "@/hooks/use-mobile";
import NavbarLogo from "./navbar/NavbarLogo";
import NavbarMenuButton from "./navbar/NavbarMenuButton";
import NavbarMobileMenu from "./navbar/NavbarMobileMenu";
import NavbarDropdown from "./navbar/NavbarDropdown";
import DesktopNavLinks from "./navbar/DesktopNavLinks";
import NavbarActions from "./navbar/NavbarActions";
import { getNavItems } from "./navbar/NavbarIcons";

const Navbar = () => {
  const isMobile = useIsMobile();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  
  const navItems = getNavItems();
  
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const scrollToSection = (href: string) => {
    document.querySelector(href)?.scrollIntoView({ behavior: 'smooth' });
    if (isMenuOpen) setIsMenuOpen(false);
    setShowDropdown(false);
  };

  return (
    <nav className={`fixed top-0 w-full z-50 transition-all duration-300 ${
      scrolled 
        ? "bg-white/95 backdrop-blur-lg shadow-md py-3" 
        : "bg-transparent py-6"
    }`}>
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between">
          <NavbarLogo scrolled={scrolled} isMobile={isMobile} />
          
          <div className="flex items-center gap-4">
            {isMobile ? (
              <>
                <NavbarMenuButton isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
                <NavbarMobileMenu 
                  isMenuOpen={isMenuOpen} 
                  navItems={navItems} 
                  scrollToSection={scrollToSection} 
                />
              </>
            ) : (
              <>
                <div className="flex items-center space-x-1">
                  <NavbarDropdown 
                    showDropdown={showDropdown} 
                    toggleDropdown={toggleDropdown} 
                    navItems={navItems} 
                    scrollToSection={scrollToSection} 
                  />
                  <DesktopNavLinks scrollToSection={scrollToSection} />
                </div>
                <NavbarActions scrollToSection={scrollToSection} />
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
