
import { 
  Info,
  Layout,
  FileText, 
  Presentation,
  Users,
  Newspaper,
  Phone
} from "lucide-react";
import React from "react";

// Export the navigation items with their icons
export const getNavItems = () => [
  { label: "About", href: "#about", icon: <Info className="h-4 w-4" /> },
  { label: "Services", href: "#services", icon: <Layout className="h-4 w-4" /> },
  { label: "Publications", href: "#publications", icon: <FileText className="h-4 w-4" /> },
  { label: "Talks", href: "#talks", icon: <Presentation className="h-4 w-4" /> },
  { label: "Leadership", href: "#leadership", icon: <Users className="h-4 w-4" /> },
  { label: "News", href: "#news", icon: <Newspaper className="h-4 w-4" /> },
  { label: "Projects", href: "#projects", icon: <Layout className="h-4 w-4" /> },
  { label: "Contact", href: "#contact", icon: <Phone className="h-4 w-4" /> },
];
