
import React from "react";
import { ArrowRight } from "lucide-react";

export interface ServiceType {
  id: string;
  title: string;
  description: string;
  icon: React.ReactNode;
  category: string;
  capabilities: string[];
}

interface ServiceCardProps {
  service: ServiceType;
  isActive: boolean;
  onClick: () => void;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ service, isActive, onClick }) => {
  // Map categories to color classes
  const getCategoryColor = (category: string) => {
    switch (category.toLowerCase()) {
      case 'healthcare':
        return {
          gradientBg: 'from-teal-500/10 to-teal-500/5',
          iconBg: 'from-teal-500/20 to-teal-500/10',
          iconText: 'text-teal-500',
          title: 'text-teal-700',
          activeBg: 'from-white to-teal-500/5',
          activeText: 'text-teal-700'
        };
      case 'technology':
        return {
          gradientBg: 'from-primary/10 to-primary/5',
          iconBg: 'from-primary/20 to-primary/10',
          iconText: 'text-primary',
          title: 'text-primary-dark',
          activeBg: 'from-white to-primary/5',
          activeText: 'text-primary'
        };
      case 'research':
        return {
          gradientBg: 'from-purple/10 to-purple/5',
          iconBg: 'from-purple/20 to-purple/10',
          iconText: 'text-purple',
          title: 'text-purple',
          activeBg: 'from-white to-purple/5',
          activeText: 'text-purple'
        };
      case 'education':
        return {
          gradientBg: 'from-amber/10 to-amber/5',
          iconBg: 'from-amber/20 to-amber/10',
          iconText: 'text-amber',
          title: 'text-amber',
          activeBg: 'from-white to-amber/5',
          activeText: 'text-amber'
        };
      default:
        return {
          gradientBg: 'from-primary/10 to-primary/5',
          iconBg: 'from-primary/20 to-primary/10',
          iconText: 'text-primary',
          title: 'text-primary-dark',
          activeBg: 'from-white to-primary/5',
          activeText: 'text-primary'
        };
    }
  };

  const colors = getCategoryColor(service.category);

  return (
    <button
      className={`w-full text-left p-6 md:p-8 rounded-2xl transition-all duration-300 h-full flex flex-col transform hover:-translate-y-1 ${
        isActive 
          ? `bg-gradient-to-br ${colors.activeBg} shadow-xl border-none` 
          : "bg-white/80 backdrop-blur-sm hover:shadow-lg border-none"
      }`}
      onClick={onClick}
    >
      <div className="flex flex-col space-y-5">
        <div className={`p-3 rounded-xl ${
          isActive 
            ? `bg-gradient-to-br ${colors.iconBg}` 
            : `bg-gradient-to-br ${colors.gradientBg}`
        } w-fit`}>
          <div className={`${isActive ? colors.iconText : colors.iconText + "/80"}`}>
            {service.icon}
          </div>
        </div>
        
        <div>
          <h4 className={`font-bold text-lg mb-3 ${
            isActive ? colors.activeText : colors.title
          }`}>
            {service.title}
          </h4>
          <p className="text-foreground/70 line-clamp-3 text-sm mb-4">
            {service.description}
          </p>
        </div>
        
        <div className="mt-auto flex items-center pt-2">
          <span className={`text-sm font-medium flex items-center gap-1 ${
            isActive ? colors.activeText : "text-foreground/60"
          }`}>
            Learn more
            <ArrowRight className="h-3.5 w-3.5 transition-transform group-hover:translate-x-1" />
          </span>
        </div>
      </div>
    </button>
  );
};

export default ServiceCard;
