
import React from "react";

const Footer = () => {
  const year = new Date().getFullYear();
  
  return (
    <footer className="bg-background py-12 border-t border-border">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="space-y-4 md:col-span-2">
            <h3 className="text-foreground font-display text-xl">Omar Costilla Reyes</h3>
            <p className="text-foreground/70">
              MIT CSAIL AI Scientist pioneering solutions at the intersection of artificial intelligence, healthcare, and scientific discovery.
            </p>
            <p className="text-foreground/70">
              CEO & Founder of Equ.care - Helping people lose weight, feel better, and take control of their health with GLP-1 medications.
            </p>
          </div>
          
          <div className="space-y-4">
            <h4 className="text-foreground font-medium">Quick Links</h4>
            <ul className="space-y-2">
              <li><a href="#about" className="text-foreground/70 hover:text-primary transition-colors">About</a></li>
              <li><a href="#expertise" className="text-foreground/70 hover:text-primary transition-colors">Expertise</a></li>
              <li><a href="#services" className="text-foreground/70 hover:text-primary transition-colors">Services</a></li>
              <li><a href="#publications" className="text-foreground/70 hover:text-primary transition-colors">Publications</a></li>
              <li><a href="#contact" className="text-foreground/70 hover:text-primary transition-colors">Contact</a></li>
            </ul>
          </div>
          
          <div className="space-y-4">
            <h4 className="text-foreground font-medium">Research Areas</h4>
            <ul className="space-y-2">
              <li><a href="#expertise" className="text-foreground/70 hover:text-primary transition-colors">AI for Healthcare</a></li>
              <li><a href="#expertise" className="text-foreground/70 hover:text-primary transition-colors">Digital Phenotyping</a></li>
              <li><a href="https://www.neurosymbolic.org/" target="_blank" rel="noopener noreferrer" className="text-foreground/70 hover:text-primary transition-colors">Neurosymbolic Programming</a></li>
              <li><a href="#projects" className="text-foreground/70 hover:text-primary transition-colors">Scientific Discovery</a></li>
              <li><a href="https://www.equ.care/" target="_blank" rel="noopener noreferrer" className="text-foreground/70 hover:text-primary transition-colors">Equ.care</a></li>
            </ul>
          </div>
        </div>
        
        <div className="pt-12 mt-12 border-t border-border flex flex-col md:flex-row justify-between items-center gap-4">
          <p className="text-foreground/50 text-sm">
            © {year} Omar Costilla Reyes. All rights reserved.
          </p>
          <p className="text-foreground/50 text-sm flex items-center gap-1">
            Contact: <a href="mailto:omar@equ.care" className="hover:text-primary transition-colors">omar@equ.care</a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
