
import React, { useState } from "react";
import { Brain, ActivitySquare, MonitorSmartphone, BarChart, Database, CircuitBoard, Microscope, HelpingHand, Code, Network, Globe } from "lucide-react";

const skillCategories = [
  {
    name: "Machine Learning",
    icon: <Brain className="h-6 w-6" />,
    description: "Expertise in deep reinforcement learning, transfer learning, generative models, few-shot learning, and explainable AI. My research focuses on developing novel neural network architectures and learning algorithms for complex problems in healthcare and scientific discovery. Specialized in spatio-temporal pattern recognition with deep residual neural networks.",
  },
  {
    name: "Computational Neuroscience",
    icon: <ActivitySquare className="h-6 w-6" />,
    description: "Specialization in neural oscillations, brain-computer interfaces, neuroimaging analysis, neural network models, and cognitive modeling. I work at the intersection of neuroscience and AI to develop models that mimic brain function and enhance AI capabilities. Experience in identifying cortical layers of the brain with machine learning techniques.",
  },
  {
    name: "Healthcare Applications",
    icon: <HelpingHand className="h-6 w-6" />,
    description: "Focus on digital phenotyping, mental health assessment, gait analysis for early detection of cognitive decline, medical image analysis, clinical decision support, and mobile health monitoring. My work aims to improve patient outcomes through innovative AI-powered healthcare tools for conditions including anxiety, depression, and other mental health disorders.",
  },
  {
    name: "Programming & Tools",
    icon: <CircuitBoard className="h-6 w-6" />,
    description: "Proficient in PyTorch, TensorFlow, JAX, Python, and C++. I develop robust, scalable solutions for complex computational problems, with an emphasis on optimizing performance and maintainability. Experience with sensor systems, computer vision, and signal processing applications.",
  },
  {
    name: "Data Science",
    icon: <BarChart className="h-6 w-6" />,
    description: "Strong background in statistical analysis, time series analysis, data visualization, experiment design, and meta-analysis. I utilize advanced statistical methods to extract meaningful insights from complex datasets including electrophysiological data, behavioral data, and sensor network data.",
  },
  {
    name: "Research Methods",
    icon: <Microscope className="h-6 w-6" />,
    description: "Expertise in neurosymbolic programming, scientific discovery, program synthesis, interdisciplinary research, and human-AI collaboration. My research methodology combines theoretical insights with practical applications, particularly focused on translating AI advances to healthcare and scientific domains.",
  },
  {
    name: "Neurosymbolic AI",
    icon: <Code className="h-6 w-6" />,
    description: "Leading research in neurosymbolic programming for science, integrating neural networks with symbolic reasoning for automated scientific theory generation. Project manager of 'Understanding the World Through Code' at MIT CSAIL, developing systems that can derive physical laws and equations from data.",
  },
  {
    name: "International AI Initiatives",
    icon: <Globe className="h-6 w-6" />,
    description: "Founded and lead the Artificial Intelligence Latin American SumMIT initiative to accelerate AI adoption across Latin America. Member of the Inter-American Committee on Science and Technology. Experience organizing international AI conferences, workshops, and educational programs.",
  }
];

const TechnicalSkills = () => {
  const [activeCategory, setActiveCategory] = useState(skillCategories[0]);

  return (
    <div className="py-6">
      <h3 className="text-2xl font-bold mb-8 text-center md:text-left">Technical Expertise</h3>
      
      <div className="flex flex-wrap gap-3 mb-8 justify-center md:justify-start">
        {skillCategories.map((category, index) => (
          <button
            key={index}
            className={`px-3 py-2 rounded-full flex items-center gap-2 transition-all duration-300 text-sm ${
              activeCategory.name === category.name 
                ? "bg-gradient-to-r from-accent to-accent-dark text-white shadow-md transform -translate-y-1" 
                : "bg-white hover:bg-primary/5 text-foreground shadow-sm hover:shadow-md"
            }`}
            onClick={() => setActiveCategory(category)}
          >
            <span className={activeCategory.name === category.name ? "text-white" : "text-accent"}>
              {category.icon}
            </span>
            <span className="font-medium">{category.name}</span>
          </button>
        ))}
      </div>
      
      <div className="bg-gradient-to-br from-white to-gray-50 shadow-lg rounded-2xl p-6 border-none animate-scale-in">
        <h4 className="text-xl font-bold mb-6 flex items-center gap-2">
          <span className="p-2 bg-accent/10 rounded-full text-accent">{activeCategory.icon}</span>
          {activeCategory.name}
        </h4>
        
        <div className="space-y-4">
          <p className="text-foreground/70 leading-relaxed">{activeCategory.description}</p>
        </div>
      </div>
    </div>
  );
};

export default TechnicalSkills;
