
import React, { useState } from "react";
import { 
  ArrowRight, ExternalLink, FileText, Code, BrainCircuit, 
  BookOpen, Microscope, Activity, Puzzle, Network, BadgeAlert,
  Award, Star, Search, ChevronDown, ChevronUp
} from "lucide-react";
import { Button } from "@/components/ui/button";

// Publication data - Updated with the exact publications from the Google Scholar profile
const publications = [
  {
    id: 1,
    title: "Analysis of spatio-temporal representations for robust footstep recognition with deep residual neural networks",
    authors: "O Costilla-Reyes, R Vera-Rodriguez, P Scully, K.B. Ozanyan",
    journal: "IEEE Transactions on Pattern Analysis and Machine Intelligence",
    year: 2018,
    citations: 105,
    categories: ["pattern-recognition", "deep-learning", "security"],
    abstract: "This paper proposes spatio-temporal footstep representations for person recognition using floor-only sensing with applications to security access scenarios and smart homes. This work is the first to study deep residual neural networks (ResNet) for the footstep recognition task. Experiments are conducted with the largest footstep database to date.",
    icon: <Network className="h-10 w-10 text-primary" />,
    paperUrl: "https://ieeexplore.ieee.org/document/8418988"
  },
  {
    id: 2,
    title: "Deep neural networks for learning spatio-temporal features from tomography sensors",
    authors: "O Costilla-Reyes, P Scully, K.B. Ozanyan",
    journal: "IEEE Transactions on Industrial Electronics",
    year: 2017,
    citations: 93,
    categories: ["deep-learning", "sensors", "industrial"],
    abstract: "This paper presents a novel approach to the problem of footstep recognition within a small group of people using pressure-sensing floor arrays. We introduce spatio-temporal raw data preprocessing methods, and deep learning architectures based on convolutional neural networks for the extraction of discriminative features directly from sensory data.",
    icon: <Activity className="h-10 w-10 text-primary" />,
    paperUrl: "https://ieeexplore.ieee.org/document/7927442"
  },
  {
    id: 3,
    title: "Temporal pattern recognition in gait activities recorded with a footprint imaging sensor system",
    authors: "O Costilla-Reyes, P Scully, K.B. Ozanyan",
    journal: "IEEE Sensors Journal",
    year: 2016,
    citations: 52,
    categories: ["pattern-recognition", "gait-analysis"],
    abstract: "This paper introduces a temporal pattern recognition approach for analyzing gait activities recorded with a footprint imaging sensor system. We demonstrate the effectiveness of this approach for identifying various gait patterns and activities.",
    icon: <Activity className="h-10 w-10 text-primary" />,
    paperUrl: "https://ieeexplore.ieee.org/document/7552522"
  },
  {
    id: 4,
    title: "Dynamic Wi-Fi fingerprinting indoor positioning system",
    authors: "O Costilla-Reyes, K Namuduri",
    journal: "International Conference on Indoor Positioning and Indoor Navigation",
    year: 2014,
    citations: 47,
    categories: ["indoor-positioning", "wifi"],
    abstract: "A novel system for indoor positioning using dynamic Wi-Fi fingerprinting techniques, demonstrating significant improvements in accuracy and reliability over conventional methods.",
    icon: <Network className="h-10 w-10 text-primary" />,
    paperUrl: "https://ieeexplore.ieee.org/document/7275525"
  },
  {
    id: 5,
    title: "Deep learning in gait analysis for security and healthcare",
    authors: "O Costilla-Reyes, R Vera-Rodriguez, A.S. Alharthi, S.U. Yunas, K.B. Ozanyan",
    journal: "Deep Learning: Algorithms and Applications",
    year: 2020,
    citations: 22,
    categories: ["deep-learning", "gait-analysis", "healthcare"],
    abstract: "This chapter explores applications of deep learning techniques to gait analysis for both security and healthcare applications, demonstrating the versatility and effectiveness of these approaches.",
    icon: <BrainCircuit className="h-10 w-10 text-primary" />,
    paperUrl: "https://link.springer.com/chapter/10.1007/978-3-030-31756-0_9"
  },
  {
    id: 6,
    title: "Spatial footstep recognition by convolutional neural networks for biometric applications",
    authors: "O Costilla-Reyes, R Vera-Rodriguez, P Scully, K.B. Ozanyan",
    journal: "IEEE SENSORS",
    year: 2016,
    citations: 17,
    categories: ["biometrics", "footstep-recognition"],
    abstract: "This paper presents a spatial footstep recognition approach using convolutional neural networks for biometric applications, demonstrating the effectiveness of these techniques for identity verification.",
    icon: <Network className="h-10 w-10 text-primary" />,
    paperUrl: "https://ieeexplore.ieee.org/document/7808530"
  },
  {
    id: 7,
    title: "Neurosymbolic programming for science",
    authors: "J.J. Sun, M. Tjandrasuwita, A. Sehgal, A. Solar-Lezama, S. Chaudhuri, Y. Yue, O. Costilla-Reyes, et al.",
    journal: "arXiv preprint",
    year: 2022,
    citations: 16,
    categories: ["neurosymbolic", "science", "programming"],
    abstract: "We explore the integration of symbolic reasoning with neural networks for scientific discovery, combining the strengths of both approaches to tackle complex problems in scientific domains. This paper presents methodologies for automated theory generation and testing with applications across multiple scientific disciplines.",
    icon: <Microscope className="h-10 w-10 text-primary" />,
    paperUrl: "https://arxiv.org/abs/2210.05050"
  },
  {
    id: 8,
    title: "Age-related differences in healthy adults walking patterns under a cognitive task with deep neural networks",
    authors: "O Costilla-Reyes, P Scully, I Leroi, K.B. Ozanyan",
    journal: "IEEE Sensors Journal",
    year: 2020,
    citations: 14,
    categories: ["healthcare", "gait-analysis"],
    abstract: "This study investigates age-related differences in walking patterns under cognitive load using deep neural networks, providing insights into functional mobility and cognitive decline risk assessment.",
    icon: <Activity className="h-10 w-10 text-primary" />,
    paperUrl: "https://ieeexplore.ieee.org/document/9151953"
  },
  {
    id: 9,
    title: "Age-sensitive differences in single and dual walking tasks from footprint floor sensor data",
    authors: "O Costilla-Reyes, P Scully, K.B. Ozanyan",
    journal: "IEEE SENSORS",
    year: 2017,
    citations: 11,
    categories: ["healthcare", "gait-analysis"],
    abstract: "This paper explores age-sensitive differences in walking patterns during single and dual tasks using data collected from footprint floor sensors, with applications in healthcare monitoring and assessment.",
    icon: <Activity className="h-10 w-10 text-primary" />,
    paperUrl: "https://ieeexplore.ieee.org/document/8234381"
  },
  {
    id: 10,
    title: "Symbolic regression with a learned concept library",
    authors: "A Grayeli, A Sehgal, O Costilla Reyes, M Cranmer, S Chaudhuri",
    journal: "Advances in Neural Information Processing Systems",
    year: 2024,
    citations: 10,
    categories: ["neurosymbolic", "concept-learning", "science"],
    abstract: "We introduce a novel approach to symbolic regression that leverages a learned library of scientific concepts. Our method combines neural networks with symbolic reasoning to discover interpretable equations from data, significantly outperforming previous methods in both accuracy and interpretability.",
    icon: <BrainCircuit className="h-10 w-10 text-primary" />,
    paperUrl: "https://proceedings.neurips.cc/paper_files/paper/2023/hash/8cc2f1ff11bfa07e0b08c46a5a966bee-Abstract-Conference.html"
  },
  {
    id: 11,
    title: "Temporal pattern recognition for gait analysis applications using an intelligent carpet system",
    authors: "O Costilla-Reyes, P Scully, K.B. Ozanyan",
    journal: "IEEE SENSORS",
    year: 2015,
    citations: 10,
    categories: ["gait-analysis", "pattern-recognition"],
    abstract: "This paper presents a novel 'intelligent carpet' system for temporal pattern recognition in gait analysis applications, with potential uses in both healthcare and security domains.",
    icon: <Activity className="h-10 w-10 text-primary" />,
    paperUrl: "https://ieeexplore.ieee.org/document/7370630"
  },
  {
    id: 12,
    title: "Predicting response to a smartphone-based cognitive-behavioral therapy for body dysmorphic disorder",
    authors: "J.L. Greenberg, H Weingarden, S.S. Hoeppner, R.M. Berger-Gutierrez, O. Costilla-Reyes, et al.",
    journal: "Journal of Affective Disorders",
    year: 2024,
    citations: 6,
    categories: ["healthcare", "mental-health"],
    abstract: "This study investigates predictors of response to smartphone-delivered cognitive-behavioral therapy for body dysmorphic disorder, providing insights into digital mental health treatment efficacy.",
    icon: <BadgeAlert className="h-10 w-10 text-primary" />,
    paperUrl: "https://www.sciencedirect.com/science/article/abs/pii/S0165032723014568"
  },
  {
    id: 13,
    title: "LEMMA: Bootstrapping high-level mathematical reasoning with learned symbolic abstractions",
    authors: "Z Li, G Poesia, O Costilla-Reyes, N Goodman, A Solar-Lezama",
    journal: "arXiv preprint",
    year: 2022,
    citations: 6,
    categories: ["neurosymbolic", "mathematics", "reasoning"],
    abstract: "We present LEMMA, a system that bootstraps high-level mathematical reasoning capabilities by learning symbolic abstractions. LEMMA demonstrates the power of combining neural and symbolic methods for complex reasoning tasks, with applications to automated theorem proving and mathematical discovery.",
    icon: <Puzzle className="h-10 w-10 text-primary" />,
    paperUrl: "https://arxiv.org/abs/2211.08671"
  },
  {
    id: 14,
    title: "Counterfactual explanations and predictive models to enhance clinical decision-making in schizophrenia using digital phenotyping",
    authors: "J.S. Canas, F. Gomez, O. Costilla-Reyes",
    journal: "arXiv preprint",
    year: 2023,
    citations: 2,
    categories: ["healthcare", "explainable-ai", "mental-health"],
    abstract: "This paper introduces explainable AI approaches for mental health applications, specifically focusing on counterfactual explanations for clinical decision support in schizophrenia treatment. We demonstrate how digital phenotyping combined with explainable models can enhance clinical decision-making while maintaining interpretability.",
    icon: <BadgeAlert className="h-10 w-10 text-primary" />,
    paperUrl: "https://arxiv.org/abs/2306.03980"
  },
  {
    id: 15,
    title: "POF Sensing Grid: Signal Analysis to extract Human Motion Characteristics",
    authors: "P Scully, O.C. Reyes, N Ahmed, J Vaughan, E Stanmore, K Ozanyan",
    journal: "International Conference on Plastic Optical Fibres",
    year: 2016,
    citations: 2,
    categories: ["sensors", "motion-analysis"],
    abstract: "This paper presents a plastic optical fiber sensing grid for extracting human motion characteristics, with applications in healthcare monitoring and mobility assessment.",
    icon: <Activity className="h-10 w-10 text-primary" />,
    paperUrl: "#"
  },
  {
    id: 16,
    title: "Individual Behavioral Insights in Schizophrenia: A Network Analysis and Mobile Sensing Approach",
    authors: "A Davies, E Fried, O Costilla-Reyes, H Aung",
    journal: "Pervasive Computing Technologies for Healthcare",
    year: 2023,
    citations: 1,
    categories: ["healthcare", "mental-health"],
    abstract: "This paper presents innovative approaches to gaining individual behavioral insights in schizophrenia using network analysis and mobile sensing technologies, offering new perspectives for personalized mental health interventions.",
    icon: <BadgeAlert className="h-10 w-10 text-primary" />,
    paperUrl: "#"
  },
  {
    id: 17,
    title: "Automatic methods for cortex-wide layer identification of electrophysiological signals reveals a cortical motif for the expression of neuronal rhythms",
    authors: "AM Bastos, O Costilla-Reyes, EK Miller",
    journal: "Cognitive Computational Neuroscience",
    year: 2019,
    citations: 1,
    categories: ["neuroscience", "signal-processing"],
    abstract: "We present novel automatic methods for cortex-wide layer identification of electrophysiological signals, revealing a cortical motif for the expression of neuronal rhythms that advances our understanding of brain function and neural processing.",
    icon: <Activity className="h-10 w-10 text-primary" />,
    paperUrl: "#"
  },
  {
    id: 18,
    title: "Unsupervised learning for spectral data analysis as a novel sensor for identifying rodent infestation in urban environments",
    authors: "O Costilla-Reyes, Z Coldrick, B Grieve",
    journal: "IEEE SENSORS",
    year: 2017,
    citations: 1,
    categories: ["unsupervised-learning", "spectral-analysis"],
    abstract: "This paper presents an unsupervised learning approach for analyzing spectral data to identify rodent infestation in urban environments, demonstrating a novel application of machine learning for pest control.",
    icon: <BrainCircuit className="h-10 w-10 text-primary" />,
    paperUrl: "https://ieeexplore.ieee.org/document/8234120"
  },
  {
    id: 19,
    title: "Cooperation of Autonomous NXT Robots Using Bluetooth Wireless Technology",
    authors: "J Bell, E Freeman, O Costilla",
    journal: "",
    year: 2013,
    citations: 1,
    categories: ["robotics", "wireless-technology"],
    abstract: "This paper explores the cooperation of autonomous NXT robots using Bluetooth wireless technology, demonstrating applications in coordinated robotic systems.",
    icon: <Network className="h-10 w-10 text-primary" />,
    paperUrl: "#"
  },
  {
    id: 20,
    title: "Estimation of total body fat using symbolic regression and evolutionary algorithms",
    authors: "JM Muñoz, O Morón-García, JI Hidalgo, O Costilla-Reyes",
    journal: "arXiv preprint",
    year: 2025,
    citations: 0,
    categories: ["symbolic-regression", "healthcare"],
    abstract: "This study presents a novel approach for estimating total body fat using symbolic regression and evolutionary algorithms, with potential applications in healthcare and fitness.",
    icon: <BrainCircuit className="h-10 w-10 text-primary" />,
    paperUrl: "https://arxiv.org/abs/2503.00594"
  },
  {
    id: 21,
    title: "InceptionSR: Recursive Symbolic Regression for Equation Synthesis",
    authors: "E Gu, S Alford, O Costilla-Reyes, M Cranmer, K Ellis",
    journal: "",
    year: 2025,
    citations: 0,
    categories: ["symbolic-regression", "equation-synthesis"],
    abstract: "We introduce InceptionSR, a recursive symbolic regression approach for equation synthesis, demonstrating improved performance in discovering complex mathematical relationships from data.",
    icon: <Puzzle className="h-10 w-10 text-primary" />,
    paperUrl: "#"
  },
  {
    id: 22,
    title: "MathDSL: A Domain-Specific Language for Concise Mathematical Solutions Via Program Synthesis",
    authors: "S Anupam, M Bowers, O Costilla-Reyes, A Solar-Lezama",
    journal: "arXiv preprint",
    year: 2024,
    citations: 0,
    categories: ["program-synthesis", "mathematics"],
    abstract: "This paper presents MathDSL, a domain-specific language designed for creating concise mathematical solutions through program synthesis, with applications in automated theorem proving and mathematical education.",
    icon: <Puzzle className="h-10 w-10 text-primary" />,
    paperUrl: "https://arxiv.org/abs/2409.17490"
  },
  {
    id: 23,
    title: "Comorbid anxiety predicts lower odds of depression improvement during smartphone-delivered psychotherapy",
    authors: "MB Talbot, JM Lipschitz, O Costilla-Reyes",
    journal: "arXiv preprint",
    year: 2024,
    citations: 0,
    categories: ["mental-health", "digital-therapy"],
    abstract: "This study investigates how comorbid anxiety affects depression improvement outcomes during smartphone-delivered psychotherapy, with implications for personalized digital mental health interventions.",
    icon: <BadgeAlert className="h-10 w-10 text-primary" />,
    paperUrl: "https://arxiv.org/abs/2409.11183"
  },
  {
    id: 24,
    title: "Pattern recognition of deep and superficial layers of the macaque brain using large-scale local field potentials",
    authors: "O Costilla-Reyes, AM Bastos, EK Miller",
    journal: "Cognitive Computational Neuroscience",
    year: 2019,
    citations: 0,
    categories: ["neuroscience", "pattern-recognition"],
    abstract: "This study explores pattern recognition techniques to identify and differentiate deep and superficial layers of the macaque brain using large-scale local field potentials, revealing distinct neural signatures across cortical regions.",
    icon: <BrainCircuit className="h-10 w-10 text-primary" />,
    paperUrl: "#"
  },
  {
    id: 25,
    title: "Pattern recognition from raw spatio-temporal data for gait analysis in healthcare and security",
    authors: "O Costilla-Reyes",
    journal: "PQDT-Global",
    year: 2018,
    citations: 0,
    categories: ["pattern-recognition", "gait-analysis"],
    abstract: "This dissertation presents novel approaches for pattern recognition from raw spatio-temporal data for gait analysis, with applications in both healthcare and security domains.",
    icon: <Activity className="h-10 w-10 text-primary" />,
    paperUrl: "#"
  },
  {
    id: 26,
    title: "iMagiMat Smart Carpet: POF Layer to Detect Gait and Mobility",
    authors: "P Scully, J Cantoral-Caballos, J Vaughan, P Wright, T Zebin, F Podd, O Costilla-Reyes",
    journal: "International Conference on Plastic Optical Fibres",
    year: 2015,
    citations: 0,
    categories: ["smart-materials", "gait-analysis"],
    abstract: "This paper presents the iMagiMat Smart Carpet, a plastic optical fiber layer for detecting gait and mobility, with applications in healthcare monitoring and assisted living.",
    icon: <Activity className="h-10 w-10 text-primary" />,
    paperUrl: "#"
  },
  {
    id: 27,
    title: "Comparison of Machine Learning Algorithms for Identifying Cancer Types",
    authors: "G Saxena, J Helsing, O Costilla-Reyes, RA",
    journal: "MidSouth Computational Biology and Bioinformatics Society Conference",
    year: 2014,
    citations: 0,
    categories: ["machine-learning", "cancer-research"],
    abstract: "This study compares various machine learning algorithms for identifying cancer types from genomic data, providing insights into the effectiveness of different approaches for cancer classification.",
    icon: <BrainCircuit className="h-10 w-10 text-primary" />,
    paperUrl: "#"
  },
  {
    id: 28,
    title: "What Can Machine Learning Infer about the Way You Walk?",
    authors: "O Costilla-Reyes, P Scully, K Ozanyan",
    journal: "Marie Curie Alumni Association",
    year: 2014,
    citations: 0,
    categories: ["machine-learning", "gait-analysis"],
    abstract: "This article explores the potential of machine learning approaches for inferring information from walking patterns, with implications for healthcare, security, and personalized medicine.",
    icon: <Activity className="h-10 w-10 text-primary" />,
    paperUrl: "#"
  }
];

// Category data for filtering
const categories = [
  { id: "all", label: "All Papers" },
  { id: "deep-learning", label: "Deep Learning" },
  { id: "neurosymbolic", label: "Neurosymbolic" },
  { id: "healthcare", label: "Healthcare" },
  { id: "mental-health", label: "Mental Health" },
  { id: "neuroscience", label: "Neuroscience" },
  { id: "science", label: "Scientific Discovery" },
  { id: "pattern-recognition", label: "Pattern Recognition" },
  { id: "explainable-ai", label: "Explainable AI" }
];

const PublicationsGallery = () => {
  const [activeCategory, setActiveCategory] = useState("all");
  const [expandedPaper, setExpandedPaper] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showAllPapers, setShowAllPapers] = useState(false);
  
  // Sort publications by citation count
  const sortedPublications = [...publications].sort((a, b) => b.citations - a.citations);
  
  // Filter publications based on active category and search term
  const filteredPublications = sortedPublications
    .filter(pub => activeCategory === "all" || pub.categories.includes(activeCategory))
    .filter(pub => 
      searchTerm === "" || 
      pub.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      pub.authors.toLowerCase().includes(searchTerm.toLowerCase()) ||
      pub.abstract.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
  // Display only top 5 papers or all based on showAllPapers state
  const displayedPublications = showAllPapers ? filteredPublications : filteredPublications.slice(0, 5);
  
  // Toggle expanded state for a paper
  const toggleExpand = (id: number) => {
    setExpandedPaper(expandedPaper === id ? null : id);
  };

  // Updated citation count to 417
  const totalCitations = 417;
  
  return (
    <section id="publications" className="py-16 bg-background">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12 space-y-3 animate-fade-in">
          <h2 className="text-accent text-lg font-medium inline-flex items-center justify-center gap-2">
            <Award className="h-4 w-4" /> Research Publications
          </h2>
          <h3 className="text-3xl font-display font-bold text-foreground leading-tight">
            Selected Publications
          </h3>
          <p className="text-lg text-foreground/70 max-w-2xl mx-auto">
            Key papers showcasing innovative research at the intersection of AI, healthcare, and scientific discovery.
          </p>
          
          {/* Citation Metrics - Updated to 2025 and 417 citations - more compact */}
          <div className="mt-6 p-5 bg-white border border-border rounded-lg shadow-sm max-w-2xl mx-auto">
            <div className="grid grid-cols-3 gap-4">
              <div className="text-center">
                <p className="text-3xl font-bold text-primary">{totalCitations}</p>
                <p className="text-sm text-foreground/70">Citations</p>
              </div>
              <div className="text-center">
                <p className="text-3xl font-bold text-primary">10</p>
                <p className="text-sm text-foreground/70">h-index</p>
              </div>
              <div className="text-center">
                <p className="text-3xl font-bold text-primary">11</p>
                <p className="text-sm text-foreground/70">i10-index</p>
              </div>
            </div>
          </div>
        </div>
        
        {/* Search and filter controls */}
        <div className="mb-8 space-y-4">
          <div className="max-w-md mx-auto">
            <div className="relative">
              <input
                type="text"
                placeholder="Search publications..."
                className="w-full p-2 pl-9 bg-white border border-border rounded-lg text-foreground focus:border-primary focus:ring-1 focus:ring-primary outline-none transition-all"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Search className="absolute left-3 top-2.5 h-4 w-4 text-foreground/50" />
            </div>
          </div>
          
          {/* Filter buttons - Horizontal scrollable on mobile */}
          <div className="flex overflow-x-auto py-2 gap-2 justify-center hide-scrollbar">
            {categories.map(category => (
              <button
                key={category.id}
                className={`px-3 py-1.5 rounded-full text-sm font-medium transition-colors whitespace-nowrap ${
                  activeCategory === category.id 
                    ? 'bg-primary text-white shadow-sm' 
                    : 'bg-white border border-border text-foreground hover:bg-primary/10'
                }`}
                onClick={() => setActiveCategory(category.id)}
              >
                {category.label}
              </button>
            ))}
          </div>
        </div>
        
        {/* Result count */}
        <div className="mb-6 text-center">
          <p className="text-sm text-foreground/70">
            {showAllPapers 
              ? `Showing all ${filteredPublications.length} matching publications` 
              : `Showing top ${Math.min(5, filteredPublications.length)} of ${filteredPublications.length} publications`}
          </p>
        </div>
        
        {/* Publications list - More compact */}
        <div className="space-y-4 mb-8">
          {displayedPublications.length > 0 ? (
            displayedPublications.map((pub) => (
              <div 
                key={pub.id} 
                className="bg-white p-4 rounded-lg border border-border hover:shadow-sm transition-all duration-300"
              >
                <div className="flex gap-4">
                  <div className="hidden sm:flex w-12 h-12 flex-shrink-0 items-center justify-center rounded-lg bg-primary/10 border border-primary/20">
                    {pub.icon}
                  </div>
                  <div className="flex-1 space-y-2">
                    <div className="flex flex-wrap items-center gap-2 text-xs">
                      <span className="text-primary font-medium">{pub.journal}</span>
                      <span className="text-foreground/50">• {pub.year}</span>
                      {pub.citations > 0 && (
                        <span className="text-foreground/50">• {pub.citations} citations</span>
                      )}
                    </div>
                    <h4 className="text-lg font-bold text-foreground">{pub.title}</h4>
                    <p className="text-sm text-foreground/70">{pub.authors}</p>
                    
                    <div className="flex flex-wrap gap-1.5">
                      {pub.categories.slice(0, 3).map((cat, idx) => (
                        <span 
                          key={idx}
                          className="px-2 py-0.5 bg-primary/5 text-xs rounded-full text-primary"
                        >
                          {cat.replace("-", " ")}
                        </span>
                      ))}
                      {pub.categories.length > 3 && (
                        <span className="text-xs text-foreground/50 px-1">+{pub.categories.length - 3} more</span>
                      )}
                    </div>
                    
                    {expandedPaper === pub.id && (
                      <div className="mt-3 space-y-3 animate-fade-in">
                        <div className="p-3 bg-primary/5 rounded-lg border border-primary/10 text-sm">
                          <p className="text-foreground/80">{pub.abstract}</p>
                        </div>
                        
                        <div className="flex gap-3">
                          <a 
                            href={pub.paperUrl} 
                            className="inline-flex items-center gap-1.5 px-3 py-1.5 bg-primary text-white text-sm rounded-lg hover:bg-primary-dark transition-colors"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FileText className="h-3.5 w-3.5" />
                            Read Paper
                          </a>
                        </div>
                      </div>
                    )}
                    
                    <div className="flex items-center justify-between pt-1">
                      <Button 
                        variant="ghost" 
                        size="sm"
                        className="text-primary hover:text-primary-dark hover:bg-primary/5 p-0 h-auto text-sm"
                        onClick={() => toggleExpand(pub.id)}
                      >
                        {expandedPaper === pub.id ? "Show Less" : "Show Details"}
                        {expandedPaper === pub.id ? 
                          <ChevronUp className="ml-1 h-3.5 w-3.5" /> : 
                          <ChevronDown className="ml-1 h-3.5 w-3.5" />
                        }
                      </Button>
                      
                      <a 
                        href={pub.paperUrl} 
                        className="p-1.5 bg-primary/10 rounded-full hover:bg-primary/20 transition-colors text-primary"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <ExternalLink className="h-4 w-4" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center py-10 bg-white rounded-lg border border-border">
              <div className="w-12 h-12 mx-auto mb-3 bg-primary/10 rounded-full flex items-center justify-center">
                <Search className="h-6 w-6 text-primary" />
              </div>
              <h4 className="text-lg font-bold text-foreground mb-1">No publications found</h4>
              <p className="text-sm text-foreground/70">Try adjusting your search criteria or select a different category</p>
              <Button 
                variant="outline" 
                size="sm"
                className="mt-3 border-primary/30 text-primary hover:bg-primary/10"
                onClick={() => {
                  setSearchTerm("");
                  setActiveCategory("all");
                }}
              >
                Reset filters
              </Button>
            </div>
          )}
          
          {/* Show more/less button */}
          {filteredPublications.length > 5 && (
            <div className="text-center mt-6">
              <Button
                variant="outline"
                className="border-primary/30 text-primary hover:bg-primary/10"
                onClick={() => setShowAllPapers(!showAllPapers)}
              >
                {showAllPapers ? 
                  <> 
                    <ChevronUp className="mr-2 h-4 w-4" />
                    Show fewer papers
                  </> : 
                  <>
                    <ChevronDown className="mr-2 h-4 w-4" />
                    Show all {filteredPublications.length} papers
                  </>
                }
              </Button>
            </div>
          )}
        </div>
        
        <div className="flex flex-col md:flex-row items-center justify-between gap-6 p-6 bg-white rounded-lg border border-border">
          <div className="flex items-center gap-4">
            <div className="p-3 bg-primary/10 rounded-full">
              <BookOpen className="h-6 w-6 text-primary" />
            </div>
            <div>
              <h4 className="text-lg font-bold text-foreground">Want to explore more research?</h4>
              <p className="text-sm text-foreground/70">View my complete publication list on Google Scholar.</p>
            </div>
          </div>
          <a
            href="https://scholar.google.com/citations?user=2XbaQy4AAAAJ&hl=en&oi=ao"
            target="_blank"
            rel="noopener noreferrer" 
            className="inline-flex items-center justify-center bg-primary text-white hover:bg-primary-dark px-4 py-2 rounded-lg font-medium transition-colors"
          >
            Google Scholar Profile
            <ArrowRight className="ml-2 h-4 w-4" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default PublicationsGallery;
