
import React from "react";
import { Button } from "@/components/ui/button";
import { ArrowLeft } from "lucide-react";
import { Link } from "react-router-dom";
import Meta from "@/components/Meta";

const NotFound = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-4 bg-background">
      <Meta 
        title="Page Not Found | Dr. Omar Costilla Reyes"
        description="The page you're looking for doesn't exist or has been moved."
        type="website"
      />
      
      <div className="max-w-md w-full text-center space-y-6">
        <h1 className="text-5xl font-display font-bold text-foreground">404</h1>
        <h2 className="text-2xl font-medium text-foreground">Page Not Found</h2>
        <p className="text-foreground/70">
          The page you're looking for doesn't exist or has been moved.
        </p>
        <Link to="/">
          <Button className="mt-8">
            <ArrowLeft className="mr-2 h-4 w-4" />
            Back to Home
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
