
import React from "react";
import { Activity, Code, Brain, GraduationCap, Stethoscope, Cpu, FlaskConical, BookOpen } from "lucide-react";

export type ServiceCategoryType = {
  id: string;
  name: string;
  icon: React.ReactNode;
  description: string;
};

export const serviceCategories: ServiceCategoryType[] = [
  {
    id: "all",
    name: "All Services",
    icon: <Cpu className="h-5 w-5" />,
    description: "Full range of AI consulting services across all domains."
  },
  {
    id: "healthcare",
    name: "Healthcare",
    icon: <Stethoscope className="h-5 w-5" />,
    description: "AI services for healthcare providers, pharmaceutical companies, and medical device manufacturers."
  },
  {
    id: "tech",
    name: "Technology",
    icon: <Cpu className="h-5 w-5" />,
    description: "AI architecture and implementation services for tech companies, focusing on cutting-edge AI technologies."
  },
  {
    id: "research",
    name: "Research",
    icon: <FlaskConical className="h-5 w-5" />,
    description: "Scientific research partnerships and AI-driven discovery services for research organizations."
  },
  {
    id: "education",
    name: "Education",
    icon: <BookOpen className="h-5 w-5" />,
    description: "AI education and training programs for organizations looking to build internal AI capabilities."
  }
];

interface ServiceCategoryProps {
  category: ServiceCategoryType;
  isActive: boolean;
  onClick: () => void;
}

const ServiceCategory: React.FC<ServiceCategoryProps> = ({ category, isActive, onClick }) => {
  // Get colors based on category
  const getCategoryColor = (categoryId: string) => {
    switch (categoryId) {
      case 'healthcare':
        return {
          activeBg: 'from-teal-500 to-teal-600',
          hoverBg: 'hover:bg-teal-500/5',
          iconBg: 'bg-teal-500/10',
          iconText: 'text-teal-500'
        };
      case 'tech':
        return {
          activeBg: 'from-primary to-primary-dark',
          hoverBg: 'hover:bg-primary/5',
          iconBg: 'bg-primary/10',
          iconText: 'text-primary'
        };
      case 'research':
        return {
          activeBg: 'from-purple to-purple/80',
          hoverBg: 'hover:bg-purple/5',
          iconBg: 'bg-purple/10',
          iconText: 'text-purple'
        };
      case 'education':
        return {
          activeBg: 'from-amber to-amber-600',
          hoverBg: 'hover:bg-amber/5',
          iconBg: 'bg-amber/10',
          iconText: 'text-amber'
        };
      default:
        return {
          activeBg: 'from-primary to-accent',
          hoverBg: 'hover:bg-primary/5',
          iconBg: 'bg-primary/10',
          iconText: 'text-primary'
        };
    }
  };

  const colors = getCategoryColor(category.id);

  return (
    <button
      className={`px-6 py-3 rounded-full flex items-center gap-2 transition-all duration-300 ${
        isActive 
          ? `bg-gradient-to-r ${colors.activeBg} text-white shadow-md transform -translate-y-1` 
          : `bg-white/90 backdrop-blur-sm ${colors.hoverBg} text-foreground shadow-sm`
      }`}
      onClick={onClick}
    >
      <span className={`p-1.5 rounded-md ${isActive ? "bg-white/20" : colors.iconBg}`}>
        <span className={isActive ? "text-white" : colors.iconText}>
          {category.icon}
        </span>
      </span>
      <span className="font-medium">{category.name}</span>
    </button>
  );
};

export default ServiceCategory;
