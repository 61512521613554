
import React from "react";

const ResearchAppointments = () => {
  return (
    <div className="space-y-8 pt-12">
      <h2 className="text-2xl md:text-3xl font-display font-bold text-foreground leading-tight text-center">
        Research Appointments
      </h2>
      
      <div className="space-y-6">
        <div className="bg-white p-6 rounded-lg border border-border shadow-sm hover:shadow-md transition-all duration-300">
          <div className="flex flex-col gap-3">
            <div className="flex items-start gap-3">
              <div className="p-2 bg-primary/10 rounded-full mt-1 w-16 h-16 flex items-center justify-center">
                <img src="/lovable-uploads/a2c9cba7-4451-42b9-a35f-6bc14c56aabc.png" alt="CSAIL Logo" className="h-10 w-auto" />
              </div>
              <div>
                <h3 className="font-bold text-foreground">Computer Science and Artificial Intelligence Laboratory (CSAIL)</h3>
                <p className="text-foreground/70">Massachusetts Institute of Technology (MIT), Boston, USA</p>
                <p className="text-foreground/70"><strong>2021 – present</strong></p>
                <p className="text-foreground/70">Research Scientist, Computer Assisted Programming group</p>
                <p className="text-foreground/70">Research project: Understanding the world through code</p>
              </div>
            </div>
          </div>
        </div>
        
        <div className="bg-white p-6 rounded-lg border border-border shadow-sm hover:shadow-md transition-all duration-300">
          <div className="flex flex-col gap-3">
            <div className="flex items-start gap-3">
              <div className="p-2 bg-primary/10 rounded-full mt-1 w-16 h-16 flex items-center justify-center">
                <img src="/lovable-uploads/499c65a5-5ec1-4d96-9631-673f6d7a8da0.png" alt="IMES Logo" className="h-10 w-auto" />
              </div>
              <div>
                <h3 className="font-bold text-foreground">Institute for Medical Engineering and Sciences (IMES)</h3>
                <p className="text-foreground/70">Massachusetts Institute of Technology (MIT), Boston, USA</p>
                <p className="text-foreground/70"><strong>2020 - 2021</strong></p>
                <p className="text-foreground/70">Postdoctoral research fellow, Edelman Lab</p>
                <p className="text-foreground/70">Research project: Digital phenotyping, artificial intelligence, and mental health</p>
              </div>
            </div>
          </div>
        </div>
        
        <div className="bg-white p-6 rounded-lg border border-border shadow-sm hover:shadow-md transition-all duration-300">
          <div className="flex flex-col gap-3">
            <div className="flex items-start gap-3">
              <div className="p-2 bg-primary/10 rounded-full mt-1 w-16 h-16 flex items-center justify-center">
                <img src="/lovable-uploads/63263384-4197-4d8a-9f2a-1b52225a5b0c.png" alt="Brain and Cognitive Sciences Logo" className="h-10 w-auto" />
              </div>
              <div>
                <h3 className="font-bold text-foreground">Brain and Cognitive Sciences (BCS)</h3>
                <p className="text-foreground/70">Massachusetts Institute of Technology (MIT), Boston, USA</p>
                <p className="text-foreground/70"><strong>2018 - 2020</strong></p>
                <p className="text-foreground/70">Postdoctoral associate and fellow</p>
                <p className="text-foreground/70">Picower Institute of Learning and Memory – Miller Laboratory</p>
                <p className="text-foreground/70">Research project: Neuroscience and artificial intelligence mechanisms for learning and memory</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResearchAppointments;
