
import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Trophy, Users, BriefcaseBusiness, GraduationCap, Globe, Sparkles } from "lucide-react";

interface LeadershipRole {
  title: string;
  organization: string;
  period: string;
  description: string;
  type: "academic" | "professional" | "community" | "international";
  link?: string;
}

const leadershipRoles: LeadershipRole[] = [
  {
    title: "Member",
    organization: "Inter-American Committee on Science and Technology – Organization of American States",
    period: "2023 - Present",
    description: "Serving as a member of the committee to advance science and technology initiatives across the Americas.",
    type: "international",
    link: "https://www.oas.org/en/cidi/comcyt.asp"
  },
  {
    title: "Vice President",
    organization: "MIT Postdoctoral Association",
    period: "2020 - 2021",
    description: "Served as Vice President of the MIT Postdoctoral Association, representing over 1,500 postdoctoral scholars at MIT.",
    type: "academic",
    link: "https://pda.mit.edu/about/officers/"
  },
  {
    title: "Project Co-lead",
    organization: "Senses and Art at MIT",
    period: "2020 - Present",
    description: "Co-leading a project aimed at alleviating anxiety and depression through art engagement, particularly during the pandemic.",
    type: "community",
    link: "http://sensesandart.mit.edu/"
  },
  {
    title: "President and Founder",
    organization: "Artificial Intelligence Latin American SumMIT",
    period: "2018 - Present",
    description: "Founded and leading an initiative to accelerate AI adoption across Latin America, organizing international summits and collaborations.",
    type: "professional",
    link: "http://ailatinsum.mit.edu/"
  },
  {
    title: "Fundraising Chair",
    organization: "MIT Postdoctoral Association",
    period: "2020 - 2021",
    description: "Responsible for fundraising initiatives to support programs for the postdoctoral community at MIT.",
    type: "academic",
    link: "https://pda.mit.edu/about/officers/"
  },
  {
    title: "Director",
    organization: "Machine Learning for Mexico, Global Mexican Talent Network (RGMX)",
    period: "2017 - 2019",
    description: "Led a group of Mexican scientists and engineers worldwide to collaborate on machine learning projects.",
    type: "international",
    link: "https://ml4mx.github.io/website/"
  },
  {
    title: "Postgraduate Student Representative",
    organization: "University of Manchester, School of Electronics Engineering",
    period: "2016 - 2017",
    description: "Represented PhD students at the liaison committee, discussing issues with the Head of School to improve postgraduate experience.",
    type: "academic"
  },
  {
    title: "Lead Organizer",
    organization: "AI-Mexico Global Symposium",
    period: "2018",
    description: "Organized the first AI-Mexico global symposium featuring technical presentations from Mexican experts in Machine learning from around the world.",
    type: "professional",
    link: "http://bit.ly/ml-mexico-2018"
  },
  {
    title: "Lead Organizer",
    organization: "Artificial Intelligence meets Neuroscience Mini-Symposium",
    period: "2019",
    description: "Organized a mini-symposium at the Society for Neuroscience conference 2019, collaborating with Gatsby research unit (UCL), NYU, Stanford, and DeepMind.",
    type: "academic",
    link: "http://bit.ly/sfn-2019-ai"
  },
  {
    title: "Vice-President",
    organization: "Dallas Chapter, Mexican Talent Network",
    period: "2014 - 2015",
    description: "Facilitated projects in business development, global innovation, and education in the Dallas–Fort Worth metroplex.",
    type: "international"
  },
  {
    title: "International Student Ambassador",
    organization: "University of North Texas",
    period: "2012 - 2014",
    description: "Represented Mexico, UNT & UNT-International to the local community, students, staff, alumni, and prospective students.",
    type: "academic"
  },
  {
    title: "IEEE Student Branch Chair",
    organization: "Autonomous University of the State of Mexico",
    period: "2009 - 2011",
    description: "Managed and organized engineering events and workshops for undergraduate students at the school of electrical and electronics department.",
    type: "academic"
  }
];

const getIconForRoleType = (type: LeadershipRole["type"]) => {
  switch (type) {
    case "academic":
      return <GraduationCap className="w-5 h-5 text-primary" />;
    case "professional":
      return <BriefcaseBusiness className="w-5 h-5 text-primary" />;
    case "community":
      return <Users className="w-5 h-5 text-primary" />;
    case "international":
      return <Globe className="w-5 h-5 text-primary" />;
    default:
      return <Trophy className="w-5 h-5 text-primary" />;
  }
};

const Leadership = () => {
  return (
    <section id="leadership" className="py-16 md:py-24 bg-background border-t border-border/50">
      <div className="container mx-auto px-4 sm:px-6">
        <div className="max-w-5xl mx-auto space-y-10">
          <div className="text-center space-y-4">
            <div className="flex items-center justify-center mb-2">
              <div className="p-2 rounded-full bg-primary/10">
                <Sparkles className="h-5 w-5 text-primary" />
              </div>
            </div>
            <h2 className="text-accent text-lg font-medium">Leadership Experience</h2>
            <h3 className="text-2xl md:text-3xl font-display font-bold text-foreground leading-tight">
              Academic & Professional Leadership
            </h3>
            <p className="text-lg text-foreground/70 max-w-3xl mx-auto">
              Leadership roles in academic, professional, and international organizations
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
            {leadershipRoles.map((role, index) => (
              <Card 
                key={index} 
                className="border border-border hover:border-primary/20 hover:shadow-md transition-all duration-300 overflow-hidden flex flex-col h-full rounded-xl"
                style={{ animationDelay: `${index * 75}ms` }}
              >
                <CardHeader className="pb-3">
                  <div className="flex items-center gap-2 mb-3">
                    <div className="p-2 bg-primary/10 rounded-full">
                      {getIconForRoleType(role.type)}
                    </div>
                    <span className="text-sm font-medium text-primary px-2 py-0.5 bg-primary/5 rounded-full">
                      {role.type}
                    </span>
                    <span className="text-sm text-foreground/60 ml-auto">
                      {role.period}
                    </span>
                  </div>
                  <CardTitle className="text-lg font-bold">{role.title}</CardTitle>
                </CardHeader>
                <CardContent className="pt-0 flex-grow">
                  <div className="mb-3 font-medium text-foreground/80">
                    {role.organization}
                  </div>
                  <p className="text-sm text-foreground/70 mb-3">
                    {role.description}
                  </p>
                  {role.link && (
                    <a 
                      href={role.link} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="inline-flex items-center text-primary text-sm hover:underline"
                    >
                      Visit website
                      <svg className="ml-1 h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                      </svg>
                    </a>
                  )}
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Leadership;
