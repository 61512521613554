
import React from "react";
import { ArrowRight, Star, Brain } from "lucide-react";
import { Button } from "@/components/ui/button";

const ConsultingCTA = () => {
  return (
    <div className="mt-16 glass-effect p-8 md:p-12 rounded-2xl">
      <div className="flex flex-col gap-8">
        <div className="space-y-6">
          <h4 className="text-3xl font-bold text-foreground">Ready to Transform Your Organization with AI?</h4>
          <p className="text-foreground/70">
            As a Research Scientist at MIT CSAIL and CEO of Equ.care, I bring a unique combination of cutting-edge academic knowledge and practical business leadership. My expertise spans healthcare AI, neurosymbolic programming, and scientific discovery - all focused on creating real-world impact for organizations like yours.
          </p>
          
          <div className="space-y-4">
            <h5 className="text-xl font-semibold">Why Work With Me:</h5>
            <ul className="space-y-4">
              <li className="flex items-start gap-2 p-4 bg-white/50 backdrop-blur-sm rounded-xl shadow-sm">
                <span className="text-accent mt-1"><Star className="h-4 w-4" /></span>
                <span><span className="font-semibold">Proven track record</span> of successful AI implementations across industries</span>
              </li>
              <li className="flex items-start gap-2 p-4 bg-white/50 backdrop-blur-sm rounded-xl shadow-sm">
                <span className="text-accent mt-1"><Star className="h-4 w-4" /></span>
                <span><span className="font-semibold">Cutting-edge expertise</span> from leading research at MIT CSAIL</span>
              </li>
              <li className="flex items-start gap-2 p-4 bg-white/50 backdrop-blur-sm rounded-xl shadow-sm">
                <span className="text-accent mt-1"><Star className="h-4 w-4" /></span>
                <span><span className="font-semibold">Business-focused approach</span> that delivers measurable ROI</span>
              </li>
            </ul>
          </div>
        </div>
        
        <div className="p-6 bg-gradient-card rounded-xl shadow-lg">
          <div className="flex items-center space-x-4 text-foreground">
            <div className="p-3 bg-primary/20 rounded-full">
              <Brain className="h-6 w-6 text-primary" />
            </div>
            <div>
              <h5 className="font-medium">Expert AI Consulting</h5>
              <p className="text-sm text-foreground/70">Bridging academic research with practical application</p>
            </div>
          </div>
        </div>
        
        <div className="flex justify-center pt-4">
          <Button 
            size="lg" 
            className="bg-gradient-to-r from-primary to-primary-dark text-white hover:opacity-90 shadow-md rounded-full px-8"
            onClick={() => document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' })}
          >
            Book a Consultation
            <ArrowRight className="ml-2 h-4 w-4" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConsultingCTA;
