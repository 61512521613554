
import React from "react";
import ResearchTimeline from "./ResearchTimeline";
import TechnicalSkills from "./TechnicalSkills";
import SpecializedKnowledge from "./SpecializedKnowledge";

const Expertise = () => {
  return (
    <section id="expertise" className="py-20 bg-background">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16 space-y-4 animate-fade-in">
          <h2 className="text-accent text-lg font-medium">Expertise Narrative</h2>
          <h3 className="text-3xl md:text-4xl font-display font-bold text-foreground leading-tight">
            Interdisciplinary Expertise & Research Journey
          </h3>
          <p className="text-xl text-foreground/70 max-w-3xl mx-auto">
            My work combines cutting-edge AI with neuroscience insights to create solutions that address complex challenges in healthcare and scientific discovery.
          </p>
        </div>
        
        {/* Research Timeline */}
        <div className="mb-20">
          <ResearchTimeline />
        </div>
        
        {/* Technical Skills */}
        <div className="mb-20">
          <TechnicalSkills />
        </div>
        
        {/* Specialized Knowledge */}
        <div>
          <SpecializedKnowledge />
        </div>
      </div>
    </section>
  );
};

export default Expertise;
