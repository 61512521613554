
import React, { useRef, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { ChevronDown } from "lucide-react";

interface NavItem {
  label: string;
  href: string;
  icon: React.ReactNode;
}

interface NavbarDropdownProps {
  showDropdown: boolean;
  toggleDropdown: () => void;
  navItems: NavItem[];
  scrollToSection: (href: string) => void;
}

const NavbarDropdown = ({ showDropdown, toggleDropdown, navItems, scrollToSection }: NavbarDropdownProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        toggleDropdown();
      }
    };

    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown, toggleDropdown]);

  return (
    <div ref={dropdownRef} className="relative">
      <Button 
        variant="ghost" 
        className="text-foreground/70 hover:text-primary hover:bg-primary-5"
        onClick={toggleDropdown}
      >
        <span>Menu</span>
        <ChevronDown className={`ml-1 h-4 w-4 transition-transform ${showDropdown ? "rotate-180" : ""}`} />
      </Button>
      
      <div className={`dropdown-content ${showDropdown ? "open" : ""}`}>
        {navItems.map((item) => (
          <a
            key={item.label}
            href={item.href}
            className="dropdown-item"
            onClick={(e) => {
              e.preventDefault();
              scrollToSection(item.href);
            }}
          >
            {item.icon}
            {item.label}
          </a>
        ))}
      </div>
    </div>
  );
};

export default NavbarDropdown;
