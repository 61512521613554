
import React from "react";
import { Button } from "@/components/ui/button";
import { Calendar, FileText } from "lucide-react";

interface NavbarActionsProps {
  scrollToSection: (href: string) => void;
}

const NavbarActions = ({ scrollToSection }: NavbarActionsProps) => {
  return (
    <div className="flex items-center gap-3">
      <a 
        href="#publications"
        className="bg-primary/10 text-primary hover:bg-primary/20 px-3 py-1.5 rounded-full flex items-center gap-1.5 transition-colors text-sm hover-lift"
        onClick={(e) => {
          e.preventDefault();
          scrollToSection('#publications');
        }}
      >
        <FileText className="h-4 w-4" />
        <span className="font-medium">Publications</span>
      </a>
      <Button 
        className="bg-gradient-to-r from-primary to-accent text-white hover:from-primary-dark hover:to-accent-dark shadow-md rounded-full hover-lift" 
        onClick={() => scrollToSection('#contact')}
        size="sm"
      >
        <Calendar className="mr-2 h-4 w-4" />
        Book a Consultation
      </Button>
    </div>
  );
};

export default NavbarActions;
