
import React from "react";
import { Button } from "@/components/ui/button";
import { Calendar } from "lucide-react";

interface NavItem {
  label: string;
  href: string;
  icon: React.ReactNode;
}

interface NavbarMobileMenuProps {
  isMenuOpen: boolean;
  navItems: NavItem[];
  scrollToSection: (href: string) => void;
}

const NavbarMobileMenu = ({ isMenuOpen, navItems, scrollToSection }: NavbarMobileMenuProps) => {
  if (!isMenuOpen) return null;
  
  return (
    <div className="fixed inset-0 top-[60px] bg-white/98 backdrop-blur-lg z-40 animate-fade-in shadow-2xl">
      <div className="container mx-auto px-4 py-6">
        <div className="flex flex-col space-y-1">
          {navItems.map((item) => (
            <a
              key={item.label}
              href={item.href}
              className="text-foreground/80 hover:text-primary hover:bg-primary-5 text-lg py-3 px-4 rounded-xl flex items-center gap-3 transition-colors"
              onClick={(e) => {
                e.preventDefault();
                scrollToSection(item.href);
              }}
            >
              <span className="p-2 rounded-full bg-primary/10 text-primary">
                {item.icon}
              </span>
              {item.label}
            </a>
          ))}
          <div className="pt-4 mt-4 border-t border-border-50">
            <Button 
              className="w-full bg-gradient-to-r from-primary to-accent text-white hover:from-primary-dark hover:to-accent-dark shadow-md" 
              onClick={() => scrollToSection('#contact')}
            >
              <Calendar className="mr-2 h-4 w-4" />
              Book a Consultation
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarMobileMenu;
