
import React from "react";
import Navbar from "@/components/Navbar";
import About from "@/components/About";
import ConsultingServices from "@/components/ConsultingServices";
import PublicationsGallery from "@/components/PublicationsGallery";
import Projects from "@/components/Projects";
import Talks from "@/components/Talks";
import News from "@/components/News";
import Leadership from "@/components/Leadership";
import Contact from "@/components/Contact";
import Footer from "@/components/Footer";
import Expertise from "@/components/Expertise";
import Meta from "@/components/Meta";

const Index = () => {
  // JSON-LD structured data for Dr. Omar Costilla Reyes
  const personJsonLd = {
    "@context": "https://schema.org",
    "@type": "Person",
    "name": "Dr. Omar Costilla Reyes",
    "jobTitle": "AI Scientist",
    "affiliation": {
      "@type": "Organization",
      "name": "MIT CSAIL"
    },
    "url": "https://omarcostilla.mit.edu",
    "sameAs": [
      "https://www.linkedin.com/in/omarcore/",
      "https://www.equ.care/",
      "http://omarcostilla.mit.edu/"
    ],
    "worksFor": [
      {
        "@type": "Organization",
        "name": "MIT CSAIL",
        "url": "https://www.csail.mit.edu/"
      },
      {
        "@type": "Organization",
        "name": "Equ.care",
        "url": "https://www.equ.care/"
      }
    ],
    "alumniOf": {
      "@type": "EducationalOrganization",
      "name": "Massachusetts Institute of Technology",
      "url": "https://www.mit.edu/"
    }
  };

  return (
    <main className="min-h-screen bg-background">
      <Meta 
        title="Dr. Omar Costilla Reyes | MIT CSAIL AI Scientist & Equ.care CEO"
        description="Dr. Omar Costilla Reyes is an AI scientist at MIT CSAIL and CEO of Equ.care, developing innovative AI solutions for healthcare and scientific discovery."
        image="/lovable-uploads/ba7f970c-cb09-469e-9341-5ca1c833bbda.png"
        jsonLd={personJsonLd}
      />
      
      <Navbar />
      <div className="flex flex-col">
        <About />
        <Expertise />
        <ConsultingServices />
        <PublicationsGallery />
        <Projects />
        <Talks />
        <Leadership />
        <News />
        <Contact />
        <Footer />
      </div>
    </main>
  );
};

export default Index;
