
import React from "react";
import { Mail, MapPin, Link as LinkIcon, ArrowRight } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/hooks/use-toast";

const Contact = () => {
  const { toast } = useToast();
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    // Simulate form submission
    setTimeout(() => {
      setIsSubmitting(false);
      setName("");
      setEmail("");
      setMessage("");
      
      toast({
        title: "Message sent!",
        description: "Thanks for reaching out. I'll get back to you soon.",
      });
    }, 1500);
  };

  return (
    <section id="contact" className="py-16 md:py-24 bg-white">
      <div className="container mx-auto px-4">
        <div className="max-w-5xl mx-auto">
          <div className="text-center space-y-3 mb-12">
            <h2 className="text-3xl md:text-4xl font-display font-bold text-foreground">
              Let's Connect
            </h2>
            <p className="text-lg text-foreground/70 max-w-2xl mx-auto">
              I'm always open to discussing new projects, collaborations, or opportunities to be part of your vision.
            </p>
          </div>
          
          <div className="flex flex-col space-y-8">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="flex flex-col items-center p-6 bg-background rounded-lg border border-border hover:shadow-md transition-all duration-300">
                <div className="p-3 bg-primary/10 rounded-full mb-4">
                  <Mail className="h-6 w-6 text-primary" />
                </div>
                <h3 className="text-lg font-semibold mb-2">Email</h3>
                <a href="mailto:omar@equ.care" className="text-foreground/70 hover:text-primary transition-colors">
                  omar@equ.care
                </a>
              </div>
              
              <div className="flex flex-col items-center p-6 bg-background rounded-lg border border-border hover:shadow-md transition-all duration-300">
                <div className="p-3 bg-primary/10 rounded-full mb-4">
                  <MapPin className="h-6 w-6 text-primary" />
                </div>
                <h3 className="text-lg font-semibold mb-2">Location</h3>
                <p className="text-foreground/70 text-center">
                  Boston, MA
                </p>
              </div>
              
              <div className="flex flex-col items-center p-6 bg-background rounded-lg border border-border hover:shadow-md transition-all duration-300">
                <div className="p-3 bg-primary/10 rounded-full mb-4">
                  <LinkIcon className="h-6 w-6 text-primary" />
                </div>
                <h3 className="text-lg font-semibold mb-2">Connect</h3>
                <a href="https://www.linkedin.com/in/omarcore/" target="_blank" rel="noopener noreferrer" className="text-foreground/70 hover:text-primary transition-colors">
                  LinkedIn
                </a>
              </div>
            </div>
            
            <form onSubmit={handleSubmit} className="space-y-6 bg-background p-8 rounded-lg border border-border mt-8">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                <div className="space-y-2">
                  <label htmlFor="name" className="text-sm font-medium">
                    Name
                  </label>
                  <Input
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Your name"
                    required
                  />
                </div>
                <div className="space-y-2">
                  <label htmlFor="email" className="text-sm font-medium">
                    Email
                  </label>
                  <Input
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Your email"
                    required
                  />
                </div>
              </div>
              <div className="space-y-2">
                <label htmlFor="message" className="text-sm font-medium">
                  Message
                </label>
                <Textarea
                  id="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Your message"
                  rows={6}
                  required
                />
              </div>
              <Button
                type="submit"
                className="w-full bg-primary text-white hover:bg-primary-dark"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Sending..." : "Send Message"}
                <ArrowRight className="ml-2 h-4 w-4" />
              </Button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
