
import React from "react";
import { Button } from "@/components/ui/button";
import { ArrowRight, ExternalLink, Mail, Linkedin, Building, Globe } from "lucide-react";

const AboutHero = () => {
  return (
    <div className="flex flex-col md:flex-row items-center gap-8 md:gap-12">
      <div className="w-full md:w-1/3 max-w-md overflow-hidden rounded-2xl border border-border shadow-lg">
        <img
          src="/lovable-uploads/e4101304-a4af-406b-8d2e-920deffde8c2.png"
          alt="Omar Costilla Reyes, Ph.D."
          className="w-full h-auto object-cover"
        />
      </div>
      
      <div className="w-full md:w-2/3 space-y-6 text-left">
        <div className="inline-flex items-center gap-2 border border-primary/20 bg-primary/5 px-4 py-2 rounded-full">
          <span className="text-primary font-medium">Omar Costilla Reyes, Ph.D.</span>
        </div>
        
        <h3 className="text-2xl md:text-3xl font-display font-bold text-foreground leading-tight">
          Merging AI Expertise with Healthcare & Scientific Discovery
        </h3>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6 text-foreground/80">
          <div className="bg-white/80 backdrop-blur-sm p-4 rounded-xl shadow-sm border border-border-50 hover-lift flex flex-col">
            <h4 className="font-bold text-primary mb-1 flex items-center gap-2">
              <Building className="h-4 w-4 text-primary" />
              Affiliation
            </h4>
            <a href="https://www.csail.mit.edu/" target="_blank" rel="noopener noreferrer" className="text-sm hover:text-primary transition-colors flex items-center gap-1">
              Computer Science and Artificial Intelligence Laboratory (CSAIL)
              <ExternalLink className="h-3 w-3" />
            </a>
            <a href="https://www.mit.edu/" target="_blank" rel="noopener noreferrer" className="text-sm hover:text-primary transition-colors flex items-center gap-1">
              Massachusetts Institute of Technology (MIT)
              <ExternalLink className="h-3 w-3" />
            </a>
          </div>
          
          <div className="bg-white/80 backdrop-blur-sm p-4 rounded-xl shadow-sm border border-border-50 hover-lift flex flex-col">
            <h4 className="font-bold text-primary mb-1 flex items-center gap-2">
              <Globe className="h-4 w-4 text-primary" />
              CEO & Founder
            </h4>
            <a href="https://www.equ.care/" target="_blank" rel="noopener noreferrer" className="text-sm hover:text-primary transition-colors flex items-center gap-1">
              Equ.care - Your Intelligent GLP-1 Medication Management Companion
              <ExternalLink className="h-3 w-3" />
            </a>
            <p className="text-sm mt-1">
              Helping people lose weight, feel better, and take control of their health with GLP-1 medications.
            </p>
          </div>
        </div>
        
        <div className="flex flex-wrap gap-3 pt-2">
          <a href="http://omarcostilla.mit.edu/" target="_blank" rel="noopener noreferrer" 
             className="inline-flex items-center gap-2 px-4 py-2 bg-white rounded-full border border-border shadow-sm hover:shadow-md transition-all hover:border-primary/30">
            <ExternalLink className="h-4 w-4 text-primary" />
            <span className="text-sm font-medium">Website</span>
          </a>
          
          <a href="https://www.linkedin.com/in/omarcore/" target="_blank" rel="noopener noreferrer"
             className="inline-flex items-center gap-2 px-4 py-2 bg-white rounded-full border border-border shadow-sm hover:shadow-md transition-all hover:border-primary/30">
            <Linkedin className="h-4 w-4 text-primary" />
            <span className="text-sm font-medium">LinkedIn</span>
          </a>
          
          <a href="mailto:omar@equ.care"
             className="inline-flex items-center gap-2 px-4 py-2 bg-white rounded-full border border-border shadow-sm hover:shadow-md transition-all hover:border-primary/30">
            <Mail className="h-4 w-4 text-primary" />
            <span className="text-sm font-medium">Email</span>
          </a>
        </div>
        
        <div className="pt-6">
          <Button size="lg" className="bg-primary text-white hover:bg-primary-dark" onClick={() => document.getElementById('publications')?.scrollIntoView({ behavior: 'smooth' })}>
            View My Research
            <ArrowRight className="ml-2 h-4 w-4" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AboutHero;
