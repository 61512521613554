
import React from "react";
import { BookOpen } from "lucide-react";

const Education = () => {
  return (
    <div className="space-y-8 pt-12">
      <h2 className="text-2xl md:text-3xl font-display font-bold text-foreground leading-tight text-center">
        Education
      </h2>
      
      <div className="space-y-6">
        <div className="bg-white p-6 rounded-lg border border-border shadow-sm hover:shadow-md transition-all duration-300">
          <div className="flex flex-col gap-3">
            <div className="flex items-start gap-3">
              <div className="p-2 bg-primary/10 rounded-full mt-1 w-10 h-10 flex items-center justify-center">
                <BookOpen className="h-5 w-5 text-primary" />
              </div>
              <div>
                <h3 className="font-bold text-foreground">University of Manchester, Manchester, UK</h3>
                <p className="text-foreground/70"><strong>2014 – 2018</strong></p>
                <p className="text-foreground/70">Ph.D. Machine learning and sensors systems</p>
                <p className="text-foreground/70">Thesis: Spatio-temporal gait pattern recognition from raw sensor data for healthcare and security</p>
              </div>
            </div>
          </div>
        </div>
        
        <div className="bg-white p-6 rounded-lg border border-border shadow-sm hover:shadow-md transition-all duration-300">
          <div className="flex flex-col gap-3">
            <div className="flex items-start gap-3">
              <div className="p-2 bg-primary/10 rounded-full mt-1 w-10 h-10 flex items-center justify-center">
                <BookOpen className="h-5 w-5 text-primary" />
              </div>
              <div>
                <h3 className="font-bold text-foreground">University of North Texas, Texas, USA</h3>
                <p className="text-foreground/70"><strong>2012 – 2014</strong></p>
                <p className="text-foreground/70">Master of Science (Honors)</p>
                <p className="text-foreground/70">Thesis: Dynamic WIFI fingerprinting indoor positioning system</p>
              </div>
            </div>
          </div>
        </div>
        
        <div className="bg-white p-6 rounded-lg border border-border shadow-sm hover:shadow-md transition-all duration-300">
          <div className="flex flex-col gap-3">
            <div className="flex items-start gap-3">
              <div className="p-2 bg-primary/10 rounded-full mt-1 w-10 h-10 flex items-center justify-center">
                <BookOpen className="h-5 w-5 text-primary" />
              </div>
              <div>
                <h3 className="font-bold text-foreground">Autonomous University of the State of Mexico, Mexico</h3>
                <p className="text-foreground/70"><strong>2007 – 2011</strong></p>
                <p className="text-foreground/70">Bachelor of Science (Honors)</p>
                <p className="text-foreground/70">Graduated with academic achievement</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Education;
