
import React from "react";
import { 
  Activity, 
  Brain, 
  Building, 
  ClipboardCheck, 
  Database, 
  LineChart, 
  Microscope, 
  Shield, 
  GraduationCap,
  Bot,
  Cpu,
  FlaskConical,
  Stethoscope,
  BookOpen,
  Network,
  Lightbulb,
  BarChart,
  Sparkles
} from "lucide-react";

export const icons = {
  brain: <Brain className="h-6 w-6 text-primary" />,
  building: <Building className="h-6 w-6 text-primary" />,
  lineChart: <BarChart className="h-6 w-6 text-primary" />,
  clipboardCheck: <ClipboardCheck className="h-6 w-6 text-primary" />,
  shield: <Shield className="h-6 w-6 text-primary" />,
  database: <Database className="h-6 w-6 text-primary" />,
  microscope: <Microscope className="h-6 w-6 text-primary" />,
  activity: <Stethoscope className="h-6 w-6 text-primary" />,
  graduationCap: <BookOpen className="h-6 w-6 text-primary" />,
  ai: <Bot className="h-6 w-6 text-primary" />,
  cpu: <Cpu className="h-6 w-6 text-primary" />,
  flask: <FlaskConical className="h-6 w-6 text-primary" />,
  network: <Network className="h-6 w-6 text-primary" />,
  idea: <Lightbulb className="h-6 w-6 text-primary" />,
  sparkles: <Sparkles className="h-6 w-6 text-primary" />
};
