
import React, { useState } from "react";
import ServiceCategory, { serviceCategories } from "./consulting/ServiceCategory";
import ServiceCard from "./consulting/ServiceCard";
import ServiceDetail from "./consulting/ServiceDetail";
import ConsultingCTA from "./consulting/ConsultingCTA";
import { services } from "./consulting/servicesData";

const ConsultingServices = () => {
  const [activeCategory, setActiveCategory] = useState(serviceCategories[0].id);
  const [activeService, setActiveService] = useState(services[0]);
  
  const filteredServices = activeCategory === "all" 
    ? services 
    : services.filter(service => service.category === activeCategory);
  
  return (
    <section id="services" className="py-16 md:py-24 bg-gradient-to-tr-primary relative overflow-hidden">
      <div className="container mx-auto px-4 relative z-10">
        <div className="text-center mb-16 space-y-4 animate-fadeInUp">
          <h2 className="text-accent font-medium">Consulting Services</h2>
          <h3 className="text-3xl md:text-4xl font-display font-bold text-foreground leading-tight">
            AI Consulting & Technical Advisory
          </h3>
          <p className="text-lg text-foreground/70 max-w-2xl mx-auto">
            Leveraging over a decade of expertise in AI research and practical applications at the intersection of healthcare and scientific discovery.
          </p>
        </div>
        
        <div className="flex flex-wrap gap-3 mb-10 justify-center">
          {serviceCategories.map((category) => (
            <ServiceCategory
              key={category.id}
              category={category}
              isActive={activeCategory === category.id}
              onClick={() => setActiveCategory(category.id)}
            />
          ))}
        </div>
        
        <div className="grid grid-cols-1 gap-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {filteredServices.slice(0, 6).map((service) => (
              <ServiceCard
                key={service.id}
                service={service}
                isActive={activeService.id === service.id}
                onClick={() => setActiveService(service)}
              />
            ))}
          </div>
          
          <div className="mt-12">
            <ServiceDetail service={activeService} />
          </div>
        </div>
        
        <ConsultingCTA />
      </div>
    </section>
  );
};

export default ConsultingServices;
