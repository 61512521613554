
import React from "react";
import { Brain, Braces, Calendar } from "lucide-react";

const ExpertiseAreas = () => {
  return (
    <div className="space-y-8 pt-12">
      <h2 className="text-2xl md:text-3xl font-display font-bold text-foreground leading-tight text-center">
        Expertise Areas
      </h2>
      
      <div className="pt-6 grid grid-cols-1 gap-4 animate-slide-up" style={{ animationDelay: "200ms" }}>
        <div className="bg-white p-5 rounded-lg border border-border shadow-sm hover:shadow-md transition-all duration-300 group">
          <div className="flex items-center gap-3 mb-3">
            <div className="p-2 bg-primary/10 rounded-full group-hover:bg-primary/20 transition-colors w-10 h-10 flex items-center justify-center">
              <Brain className="h-5 w-5 text-primary" />
            </div>
            <h3 className="text-lg font-bold text-foreground">AI for Healthcare</h3>
          </div>
          <p className="text-sm text-foreground/70">
            Leading expert in behavior analysis, digital phenotyping, and interpretable machine learning for mental health disorders.
          </p>
        </div>
        
        <div className="bg-white p-5 rounded-lg border border-border shadow-sm hover:shadow-md transition-all duration-300 group">
          <div className="flex items-center gap-3 mb-3">
            <div className="p-2 bg-primary/10 rounded-full group-hover:bg-primary/20 transition-colors w-10 h-10 flex items-center justify-center">
              <Braces className="h-5 w-5 text-primary" />
            </div>
            <h3 className="text-lg font-bold text-foreground">Scientific Discovery</h3>
          </div>
          <p className="text-sm text-foreground/70">
            Integrating AI with program synthesis to revolutionize data-driven scientific theory generation and innovation.
          </p>
        </div>
        
        <div className="bg-white p-5 rounded-lg border border-border shadow-sm hover:shadow-md transition-all duration-300 group">
          <div className="flex items-center gap-3 mb-3">
            <div className="p-2 bg-primary/10 rounded-full group-hover:bg-primary/20 transition-colors w-10 h-10 flex items-center justify-center">
              <Calendar className="h-5 w-5 text-primary" />
            </div>
            <h3 className="text-lg font-bold text-foreground">Digital Health</h3>
          </div>
          <p className="text-sm text-foreground/70">
            Utilizing mobile sensing and network analysis to gain insights into mental health conditions and provide personalized interventions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ExpertiseAreas;
