
import { createContext, useContext } from "react";

type Theme = "light";

const ThemeContext = createContext<{
  theme: Theme;
}>({
  theme: "light",
});

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <ThemeContext.Provider value={{ theme: "light" }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
