
import React from "react";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Mic, Users, Calendar, Video, MapPin, ExternalLink } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";

interface Talk {
  title: string;
  event: string;
  date: string;
  location: string;
  type: "keynote" | "panel" | "workshop" | "conference";
  link?: string;
}

const featuredTalks: Talk[] = [
  {
    title: "Neurosymbolic Programming for Science",
    event: "Data Science Ghent – Open Generative AI",
    date: "2023",
    location: "Ghent, Belgium",
    type: "keynote",
    link: "http://www.neurosymbolic.org/"
  },
  {
    title: "Artificial Intelligence: Public Policy Imperatives for the Americas",
    event: "Tenth Meeting of the Inter-American Committee on Science and Technology",
    date: "2023",
    location: "Washington, DC, USA",
    type: "keynote",
    link: "https://www.oas.org/en/cidi/comcyt.asp"
  },
  {
    title: "Colombia World Security Summit",
    event: "Colombia World Security Summit 2023",
    date: "2023",
    location: "Medellin, Colombia",
    type: "keynote",
    link: "https://colombiaworldsummit.com/"
  },
  {
    title: "Mental health technology panel",
    event: "Davos Lab, World Economic Forum",
    date: "2022",
    location: "Davos, Switzerland",
    type: "panel",
    link: "https://www.weforum.org/events/davos-lab-2022/"
  },
  {
    title: "AI Latin American Summit: Reflexiones, desafíos y oportunidades",
    event: "Banco Interamericano de Desarrollo",
    date: "February 2021",
    location: "Virtual",
    type: "keynote"
  },
  {
    title: "Digital health and Artificial Intelligence",
    event: "Department of Biostatistics Harvard T.H. Chan School of Public Health",
    date: "July 2020",
    location: "Harvard University - Onnela Lab",
    type: "conference",
    link: "https://www.hsph.harvard.edu/biostatistics/"
  },
  {
    title: "Artificial intelligence and Latin America for the social benefit",
    event: "AI and Governance Forum of the World Artificial Intelligence Conference",
    date: "June 2020",
    location: "Shanghai, China (Virtual)",
    type: "conference",
    link: "https://www.worldaic.com.cn/en/"
  },
  {
    title: "Digital health and technology",
    event: "Experimental learning opportunity program (ELO)",
    date: "2021",
    location: "MIT, Cambridge, MA",
    type: "workshop",
    link: "https://experiential.mit.edu/"
  },
  {
    title: "Artificial intelligence meets neuroscience",
    event: "MIT Cambridge Science Festival",
    date: "April 2019",
    location: "Cambridge, MA",
    type: "panel",
    link: "http://bit.ly/ai-meets-neuro-MIT"
  },
  {
    title: "Deep fakes and its implications to society",
    event: "UNESCO International Conference 'The Fight against Xenophobia in the Era of Disinformation and Artificial Intelligence'",
    date: "February 2020",
    location: "UNESCO",
    type: "conference",
    link: "http://bit.ly/unesco-ai-talk"
  },
  {
    title: "Analysis of multimodal human-generated sensor data: a deep learning approach for early detection of dementia",
    event: "NVIDIA GPU Technology Conference",
    date: "March 2018",
    location: "San Francisco, California, USA",
    type: "conference"
  },
  {
    title: "Deep Residual Neural Networks for Robust Footstep Recognition",
    event: "Max Planck Institute for Intelligent Systems",
    date: "March 2018",
    location: "Tubingen, Germany",
    type: "conference"
  }
];

const getIconForTalkType = (type: Talk["type"]) => {
  switch (type) {
    case "keynote":
      return <Mic className="w-5 h-5 text-primary" />;
    case "panel":
      return <Users className="w-5 h-5 text-primary" />;
    case "workshop":
      return <Calendar className="w-5 h-5 text-primary" />;
    case "conference":
      return <Video className="w-5 h-5 text-primary" />;
    default:
      return <Mic className="w-5 h-5 text-primary" />;
  }
};

const Talks = () => {
  return (
    <section id="talks" className="py-16 md:py-24 bg-background">
      <div className="container mx-auto px-4 sm:px-6">
        <div className="max-w-6xl mx-auto space-y-10">
          <div className="text-center space-y-4">
            <h2 className="text-2xl md:text-3xl font-display font-bold text-foreground leading-tight">
              Selected International Talks
            </h2>
            <p className="text-lg text-foreground/70 max-w-3xl mx-auto">
              Sharing expertise and insights at prestigious global events and institutions
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-8">
            {featuredTalks.map((talk, index) => (
              <Card 
                key={index} 
                className="border border-border hover:shadow-md transition-all duration-300 overflow-hidden flex flex-col h-full"
              >
                <CardHeader className="pb-3">
                  <div className="flex items-center gap-2 mb-2">
                    <div className="p-2 bg-primary/10 rounded-full">
                      {getIconForTalkType(talk.type)}
                    </div>
                    <Badge className="bg-primary/10 text-primary hover:bg-primary/20">
                      {talk.type}
                    </Badge>
                  </div>
                  <CardTitle className="text-lg font-bold line-clamp-2">{talk.title}</CardTitle>
                  <CardDescription className="line-clamp-2">{talk.event}</CardDescription>
                </CardHeader>
                <CardContent className="pt-0 flex-grow flex flex-col">
                  <div className="space-y-2 text-sm text-foreground/70 mb-4">
                    <div className="flex items-center gap-2">
                      <Calendar className="h-4 w-4 text-foreground/60" />
                      <span>{talk.date}</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <MapPin className="h-4 w-4 text-foreground/60" />
                      <span>{talk.location}</span>
                    </div>
                  </div>
                  {talk.link && (
                    <div className="mt-auto pt-2">
                      <a 
                        href={talk.link} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="inline-flex items-center text-primary text-sm hover:underline"
                      >
                        View details
                        <ExternalLink className="ml-1 h-3 w-3" />
                      </a>
                    </div>
                  )}
                </CardContent>
              </Card>
            ))}
          </div>
          
          <div className="text-center mt-8">
            <Button 
              variant="outline" 
              className="border-primary text-primary hover:bg-primary/5"
              onClick={() => window.open("mailto:omar@equ.care?subject=Speaking%20Engagement%20Request", "_blank")}
            >
              <Calendar className="mr-2 h-4 w-4" />
              Request a Talk
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Talks;
