
import React from "react";
import { GraduationCap, Briefcase, Brain, Code, TestTube, Award, Microscope, Globe, BookOpen } from "lucide-react";

const timelineData = [
  {
    year: "2024-Present",
    title: "CEO and Founder",
    institution: "Equ.care",
    description: "Leading an innovative AI-powered GLP-1 management platform helping people lose weight, feel better, and take control of their health with GLP-1 medications.",
    icon: <Briefcase className="h-5 w-5 text-accent" />,
    logo: null,
    logoAlt: "Equ.care Logo",
    skills: ["AI in Healthcare", "GLP-1 Management", "Digital Health", "Cultural Healthcare"],
    link: "https://www.equ.care/"
  },
  {
    year: "2021-Present",
    title: "Research Scientist",
    institution: "Computer Science and Artificial Intelligence Laboratory (CSAIL), MIT",
    description: "Leading interdisciplinary research in AI, with focus on neurosymbolic programming for healthcare and scientific discovery. Project Manager for 'Understanding the World Through Code'.",
    icon: <Code className="h-5 w-5 text-accent" />,
    logo: "/lovable-uploads/a2c9cba7-4451-42b9-a35f-6bc14c56aabc.png",
    logoAlt: "CSAIL Logo", 
    skills: ["Neurosymbolic Programming", "AI for Healthcare", "Scientific Discovery", "Project Management"],
    link: "https://www.csail.mit.edu/"
  },
  {
    year: "2020-2021",
    title: "Postdoctoral Research Fellow",
    institution: "Institute for Medical Engineering and Sciences (IMES), MIT",
    description: "Led research on digital phenotyping and AI applications in mental health, focusing on anxiety and depression disorders in the Edelman Lab.",
    icon: <TestTube className="h-5 w-5 text-accent" />,
    logo: "/lovable-uploads/499c65a5-5ec1-4d96-9631-673f6d7a8da0.png",
    logoAlt: "IMES Logo",
    skills: ["Digital Phenotyping", "Mental Health", "AI in Healthcare", "Mobile Sensing"],
    link: "https://imes.mit.edu/"
  },
  {
    year: "2018-2020",
    title: "Postdoctoral Associate and Fellow",
    institution: "Department of Brain and Cognitive Sciences, MIT",
    description: "Researched neural oscillations and intelligent behavior using machine learning at the Picower Institute for Learning and Memory in the Miller Laboratory.",
    icon: <Brain className="h-5 w-5 text-accent" />,
    logo: "/lovable-uploads/63263384-4197-4d8a-9f2a-1b52225a5b0c.png",
    logoAlt: "Brain and Cognitive Sciences Logo",
    skills: ["Neuroscience", "Machine Learning", "Neural Networks", "Electrophysiological Data Analysis"],
    link: "https://bcs.mit.edu/"
  },
  {
    year: "2014-2018",
    title: "PhD in Electrical and Electronics Engineering",
    institution: "University of Manchester, UK",
    description: "Focused on pattern recognition from raw spatio-temporal data for gait analysis in healthcare and security applications. Thesis: Spatio-temporal gait pattern recognition from raw sensor data for healthcare and security.",
    icon: <GraduationCap className="h-5 w-5 text-accent" />,
    logo: null,
    logoAlt: "University of Manchester Logo",
    skills: ["Deep Learning", "Gait Analysis", "Pattern Recognition", "Sensor Systems"],
    link: "https://www.manchester.ac.uk/"
  },
  {
    year: "2012-2014",
    title: "Master of Science in Electrical Engineering",
    institution: "University of North Texas, USA",
    description: "Research on indoor positioning systems using dynamic WiFi fingerprinting. Thesis: Dynamic WIFI fingerprinting indoor positioning system.",
    icon: <GraduationCap className="h-5 w-5 text-accent" />,
    logo: null,
    logoAlt: "University of North Texas Logo",
    skills: ["Indoor Positioning", "WiFi Fingerprinting", "Signal Processing", "Wireless Networks"],
    link: "https://www.unt.edu/"
  },
  {
    year: "2007-2011",
    title: "Bachelor of Science in Electronics Engineering",
    institution: "Autonomous University of the State of Mexico, Mexico",
    description: "Graduated with honors and academic achievement. Founded and led the IEEE student branch.",
    icon: <GraduationCap className="h-5 w-5 text-accent" />,
    logo: null,
    logoAlt: "UAEM Logo",
    skills: ["Electronics", "Engineering Fundamentals", "Student Leadership"],
    link: "https://www.uaemex.mx/"
  }
];

const ResearchTimeline = () => {
  return (
    <div className="py-6">
      <h3 className="text-2xl font-bold mb-8 text-center md:text-left">Research Journey</h3>
      <div className="space-y-8">
        {timelineData.map((item, index) => (
          <div 
            key={index} 
            className="timeline-item relative bg-white p-6 rounded-xl border border-border hover:border-primary/30 hover:shadow-md transition-all duration-300"
            style={{ animationDelay: `${index * 150}ms` }}
          >
            <div className="flex flex-col md:flex-row md:items-center gap-4 mb-2">
              <div className="flex items-center gap-2">
                <span className="text-accent text-sm font-medium">{item.year}</span>
                <div className="p-2 bg-primary/10 rounded-full w-12 h-12 flex items-center justify-center">
                  {item.logo ? (
                    <img src={item.logo} alt={item.logoAlt} className="h-7 w-auto" />
                  ) : (
                    item.icon
                  )}
                </div>
              </div>
              <div>
                <h4 className="text-xl font-bold">{item.title}</h4>
                <a 
                  href={item.link} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-foreground/70 hover:text-primary transition-colors"
                >
                  {item.institution}
                </a>
              </div>
            </div>
            <p className="text-foreground/80 mt-2 mb-3">{item.description}</p>
            <div className="flex flex-wrap gap-2">
              {item.skills.map((skill, idx) => (
                <span 
                  key={idx} 
                  className="text-xs px-2 py-1 rounded-full bg-primary/10 text-primary-dark dark:text-primary-light"
                >
                  {skill}
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResearchTimeline;
