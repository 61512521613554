
import React from "react";
import { Award } from "lucide-react";

const Awards = () => {
  return (
    <div className="space-y-8 pt-12">
      <h2 className="text-2xl md:text-3xl font-display font-bold text-foreground leading-tight text-center">
        Selected Awards & Honors
      </h2>
      
      <div className="grid grid-cols-1 gap-4">
        <div className="bg-white p-5 rounded-lg border border-border shadow-sm hover:shadow-md transition-all duration-300 group">
          <div className="flex items-center gap-3 mb-3">
            <div className="p-2 bg-primary/10 rounded-full group-hover:bg-primary/20 transition-colors w-10 h-10 flex items-center justify-center">
              <Award className="h-5 w-5 text-primary" />
            </div>
            <h3 className="text-lg font-bold text-foreground">Microsoft Cloud Computing Educational Grant (2022)</h3>
          </div>
        </div>
        
        <div className="bg-white p-5 rounded-lg border border-border shadow-sm hover:shadow-md transition-all duration-300 group">
          <div className="flex items-center gap-3 mb-3">
            <div className="p-2 bg-primary/10 rounded-full group-hover:bg-primary/20 transition-colors w-10 h-10 flex items-center justify-center">
              <Award className="h-5 w-5 text-primary" />
            </div>
            <h3 className="text-lg font-bold text-foreground">MIT MISTI Mexico Seed Grant Award (2021)</h3>
          </div>
        </div>
        
        <div className="bg-white p-5 rounded-lg border border-border shadow-sm hover:shadow-md transition-all duration-300 group">
          <div className="flex items-center gap-3 mb-3">
            <div className="p-2 bg-primary/10 rounded-full group-hover:bg-primary/20 transition-colors w-10 h-10 flex items-center justify-center">
              <Award className="h-5 w-5 text-primary" />
            </div>
            <h3 className="text-lg font-bold text-foreground">NSF Innovation Award (2021)</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Awards;
