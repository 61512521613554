
import React from "react";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Newspaper, Calendar, ArrowRight, Award, FileText, Sparkles } from "lucide-react";
import { Button } from "@/components/ui/button";

interface NewsItem {
  title: string;
  date: string;
  description: string;
  category: "Research" | "Press" | "Events" | "Awards";
  link?: string;
}

const newsItems: NewsItem[] = [
  {
    title: "Neurosymbolic Programming for Science Featured at Data Science Ghent Conference",
    date: "December 2023",
    description: "Dr. Costilla-Reyes presented groundbreaking research on neurosymbolic programming approaches for scientific discovery at the Data Science Ghent – Open Generative AI conference.",
    category: "Research",
    link: "http://www.neurosymbolic.org/"
  },
  {
    title: "Speaker at Tenth Meeting of the Inter-American Committee on Science and Technology",
    date: "2023",
    description: "Addressed the Organization of American States on 'Artificial Intelligence: Public Policy Imperatives for the Americas'.",
    category: "Events",
    link: "https://www.oas.org/en/cidi/comcyt.asp"
  },
  {
    title: "Microsoft Cloud Computing Educational Grant",
    date: "2022",
    description: "Awarded a $25,000 educational grant from Microsoft to support research and teaching in cloud computing and AI applications.",
    category: "Awards",
    link: "https://www.microsoft.com/en-us/research/academic-programs/"
  },
  {
    title: "MIT MISTI Mexico Seed Grant Award",
    date: "October 2021",
    description: "Received the MIT MISTI Mexico Seed Grant Award for innovative research in mental health technology applications.",
    category: "Awards",
    link: "https://misti.mit.edu/mit-sloan-latin-america-office"
  },
  {
    title: "NSF Innovation Award",
    date: "2021",
    description: "Received the National Science Foundation Innovation Award for pioneering work in digital health applications using artificial intelligence.",
    category: "Awards",
    link: "https://www.nsf.gov/awardsearch/"
  },
  {
    title: "A Pandemic-Prompted Experiment in How We Engage with Art",
    date: "July 2021",
    description: "Dr. Costilla-Reyes' work with Senses and Art at MIT featured in MIT Alumni publication, highlighting innovative approaches to reducing anxiety and depression through art engagement.",
    category: "Press",
    link: "https://alum.mit.edu/slice/pandemic-prompted-experiment-how-we-engage-art"
  },
  {
    title: "Google Education Award",
    date: "2020",
    description: "Received a $20,000 Google for Education research grant to support research and teaching in AI.",
    category: "Awards",
    link: "https://edu.google.com/programs/grants/"
  },
  {
    title: "MIT Picower Institute Postdoctoral Fellowship",
    date: "2021",
    description: "Awarded prestigious fellowship for research at the intersection of neuroscience and artificial intelligence.",
    category: "Awards"
  },
  {
    title: "Students push to speed up artificial intelligence adoption in Latin America",
    date: "November 2019",
    description: "MIT News covers the Artificial Intelligence Latin American SumMIT initiative led by Dr. Costilla-Reyes to accelerate AI adoption across Latin America.",
    category: "Press",
    link: "http://news.mit.edu/2019/students-push-to-speed-up-ai-adoption-latin-america-1119"
  },
  {
    title: "This AI Knows Who You Are by the Way You Walk",
    date: "June 2018",
    description: "Gizmodo features groundbreaking research on AI footstep recognition systems developed for security applications.",
    category: "Press",
    link: "https://gizmodo.com/this-ai-knows-who-you-are-by-the-way-you-walk-1826368997"
  },
  {
    title: "InAbstract Award",
    date: "2018",
    description: "University of Manchester award recognizing 'pick of the very best new research coming from the Faculty of Science and Engineering', representing the School of EEE.",
    category: "Awards"
  },
  {
    title: "No.1 Popular Research Article at IEEE Transactions of Industrial Electronics",
    date: "February 2018",
    description: "The paper 'Deep Neural Networks for Learning Spatio-Temporal Features from Tomography Sensors' was recognized as the most popular article.",
    category: "Research"
  }
];

const getCategoryColor = (category: NewsItem["category"]) => {
  switch (category) {
    case "Research":
      return "bg-primary/10 text-primary hover:bg-primary/20";
    case "Press":
      return "bg-accent/10 text-accent hover:bg-accent/20";
    case "Events":
      return "bg-purple-500/10 text-purple-500 hover:bg-purple-500/20";
    case "Awards":
      return "bg-amber-500/10 text-amber-600 hover:bg-amber-500/20";
    default:
      return "bg-gray-100 text-gray-800 hover:bg-gray-200";
  }
};

const News = () => {
  return (
    <section id="news" className="py-16 md:py-24 bg-gradient-to-tr-primary">
      <div className="container mx-auto px-4 sm:px-6">
        <div className="max-w-5xl mx-auto space-y-10">
          <div className="text-center space-y-4 mb-10">
            <div className="flex items-center justify-center mb-2">
              <div className="p-2 rounded-full bg-primary/10">
                <Sparkles className="h-5 w-5 text-primary" />
              </div>
            </div>
            <h2 className="text-accent text-lg font-medium">News & Awards</h2>
            <h3 className="text-2xl md:text-3xl font-display font-bold text-foreground leading-tight">
              Latest Updates & Recognitions
            </h3>
            <p className="text-lg text-foreground/70 max-w-3xl mx-auto">
              Recent research publications, press coverage, awards, and announcements
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {newsItems.map((item, index) => (
              <Card 
                key={index} 
                className="border border-border hover:border-primary/20 hover:shadow-md transition-all duration-300 h-full flex flex-col rounded-xl"
                style={{ animationDelay: `${index * 50}ms` }}
              >
                <CardHeader className="pb-2">
                  <div className="flex justify-between items-start mb-2">
                    <Badge className={`${getCategoryColor(item.category)} rounded-full px-3`}>
                      {item.category}
                    </Badge>
                    <div className="flex items-center text-sm text-foreground/60">
                      <Calendar className="h-3.5 w-3.5 mr-1" />
                      {item.date}
                    </div>
                  </div>
                  <CardTitle className="text-lg font-bold hover:text-primary transition-colors">{item.title}</CardTitle>
                </CardHeader>
                <CardContent className="flex-grow flex flex-col">
                  <CardDescription className="text-foreground/70 mb-4 line-clamp-3">
                    {item.description}
                  </CardDescription>
                  {item.link && (
                    <div className="mt-auto pt-2">
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center text-primary text-sm font-medium hover:underline"
                      >
                        Read more
                        <ArrowRight className="ml-1 h-3.5 w-3.5" />
                      </a>
                    </div>
                  )}
                </CardContent>
              </Card>
            ))}
          </div>
          
          <div className="flex justify-center mt-8">
            <Button 
              variant="outline" 
              className="border-primary text-primary hover:bg-primary/5 rounded-full"
              onClick={() => window.open("http://news.mit.edu/", "_blank")}
            >
              <Newspaper className="mr-2 h-4 w-4" />
              View More News
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default News;
