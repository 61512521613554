
import React from "react";

interface DesktopNavLinksProps {
  scrollToSection: (href: string) => void;
}

const DesktopNavLinks = ({ scrollToSection }: DesktopNavLinksProps) => {
  return (
    <div className="hidden md:flex items-center space-x-1">
      <a
        href="#about"
        className="px-3 py-2 text-foreground/70 hover:text-primary transition-colors rounded-md hover:bg-primary-5"
        onClick={(e) => {
          e.preventDefault();
          scrollToSection('#about');
        }}
      >
        About
      </a>
      <a
        href="#services"
        className="px-3 py-2 text-foreground/70 hover:text-primary transition-colors rounded-md hover:bg-primary-5"
        onClick={(e) => {
          e.preventDefault();
          scrollToSection('#services');
        }}
      >
        Services
      </a>
      <a
        href="#publications"
        className="px-3 py-2 text-foreground/70 hover:text-primary transition-colors rounded-md hover:bg-primary-5"
        onClick={(e) => {
          e.preventDefault();
          scrollToSection('#publications');
        }}
      >
        Research
      </a>
    </div>
  );
};

export default DesktopNavLinks;
