
import React from "react";
import AboutHero from "./about/AboutHero";
import ResearchAppointments from "./about/ResearchAppointments";
import Education from "./about/Education";
import Awards from "./about/Awards";
import ExpertiseAreas from "./about/ExpertiseAreas";

const About = () => {
  return (
    <section id="about" className="py-16 md:py-24 pt-24 bg-gradient-to-tr-primary">
      <div className="container mx-auto px-4 sm:px-6">
        <div className="max-w-5xl mx-auto space-y-10">
          <div className="text-center space-y-4 animate-fade-in">
            <span className="text-accent text-lg font-medium inline-flex items-center justify-center gap-2">
              MIT CSAIL AI Scientist & Equ.care CEO
            </span>
            <h1 className="text-3xl md:text-5xl font-display font-bold text-foreground leading-tight">
              OMAR COSTILLA-REYES
            </h1>
            <p className="text-lg md:text-xl text-foreground/70 max-w-3xl mx-auto pt-2">
              Developing innovative AI solutions for healthcare and scientific discovery.
            </p>
          </div>
          
          <AboutHero />
          <ResearchAppointments />
          <Education />
          <Awards />
          <ExpertiseAreas />
        </div>
      </div>
    </section>
  );
};

export default About;
