import React from "react";
import { ArrowRight, Github, ExternalLink, Brain, Activity, Users, GraduationCap, Sparkles, Network, Globe, Database } from "lucide-react";
import { Button } from "@/components/ui/button";

const projects = [
  {
    title: "Equ.care",
    description: "Helping people lose weight, feel better, and take control of their health with GLP-1 medications.",
    category: "Healthcare AI",
    icon: <Users className="h-6 w-6" />,
    links: [
      { type: "website", url: "https://www.equ.care/" }
    ]
  },
  {
    title: "Understanding the World Through Code",
    description: "Neurosymbolic programming framework for generating scientific theories from data. Project Manager at MIT CSAIL.",
    category: "Scientific AI",
    icon: <Brain className="h-6 w-6" />,
    links: [
      { type: "website", url: "http://www.neurosymbolic.org/" },
      { type: "paper", url: "https://arxiv.org/abs/2210.05050" },
    ]
  },
  {
    title: "LEMMA",
    description: "Bootstrapping High-Level Mathematical Reasoning with Learned Symbolic Abstractions. A neurosymbolic approach to mathematical reasoning.",
    category: "Scientific AI",
    icon: <Database className="h-6 w-6" />,
    links: [
      { type: "paper", url: "https://arxiv.org/abs/2211.08671" },
    ]
  },
  {
    title: "Digital Phenotyping Platform",
    description: "Mobile sensing and machine learning framework for mental health assessment and personalized interventions.",
    category: "Healthcare AI",
    icon: <Activity className="h-6 w-6" />,
    links: [
      { type: "website", url: "https://www.equ.care/" },
      { type: "paper", url: "https://arxiv.org/abs/2306.03980" },
    ]
  },
  {
    title: "Senses and Art at MIT",
    description: "Project aimed at alleviating anxiety and depression through art engagement, particularly during the pandemic.",
    category: "Healthcare",
    icon: <Network className="h-6 w-6" />,
    links: [
      { type: "website", url: "http://sensesandart.mit.edu/" },
    ]
  },
  {
    title: "Artificial Intelligence Latin American SumMIT",
    description: "Initiative to accelerate AI adoption across Latin America through international summits, collaborations, and educational programs.",
    category: "Education",
    icon: <Globe className="h-6 w-6" />,
    links: [
      { type: "website", url: "http://ailatinsum.mit.edu/" },
    ]
  },
  {
    title: "Spatio-temporal Gait Recognition",
    description: "Deep learning framework for analyzing walking patterns for security applications and early detection of cognitive decline.",
    category: "Healthcare & Security",
    icon: <Network className="h-6 w-6" />,
    links: [
      { type: "paper", url: "https://ieeexplore.ieee.org/document/8510796" },
    ]
  },
  {
    title: "AI Education Initiative",
    description: "Developing comprehensive AI curriculum for healthcare professionals and researchers.",
    category: "Education",
    icon: <GraduationCap className="h-6 w-6" />,
    links: [
      { type: "website", url: "http://www.neurosymbolic.org/aiscience.html" },
    ]
  }
];

const Projects = () => {
  return (
    <section id="projects" className="py-16 md:py-24 bg-background border-t border-border">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12 space-y-4 animate-fade-in">
          <h2 className="text-accent text-lg font-medium">Projects & Innovations</h2>
          <h3 className="text-2xl md:text-3xl font-display font-bold text-foreground leading-tight">
            Featured Research Projects
          </h3>
          <p className="text-lg text-foreground/70 max-w-2xl mx-auto">
            A selection of most impactful work at the intersection of AI, healthcare, and scientific discovery.
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-5xl mx-auto">
          {projects.map((project, index) => (
            <div 
              key={index} 
              className="group bg-white rounded-xl overflow-hidden border border-border shadow-sm hover:shadow-md transition-all duration-300 p-6"
              style={{ animationDelay: `${index * 100}ms` }}
            >
              <div className="flex flex-col md:flex-row gap-4">
                <div className="p-4 bg-primary-light/10 rounded-full text-primary flex-shrink-0 self-start">
                  {project.icon}
                </div>
                <div>
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-primary text-sm font-medium px-3 py-1 bg-primary/5 rounded-full">{project.category}</span>
                    <div className="flex space-x-2">
                      {project.links.map((link, i) => (
                        <a 
                          key={i} 
                          href={link.url} 
                          className="text-foreground/60 hover:text-primary transition-colors p-2 hover:bg-primary/5 rounded-full"
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label={`${link.type} link for ${project.title}`}
                        >
                          {link.type === "github" ? <Github className="h-4 w-4" /> : 
                           link.type === "website" ? <ExternalLink className="h-4 w-4" /> :
                           <ArrowRight className="h-4 w-4" />}
                        </a>
                      ))}
                    </div>
                  </div>
                  
                  <h4 className="text-xl font-bold text-foreground mb-2 group-hover:text-primary transition-colors">{project.title}</h4>
                  <p className="text-foreground/70">{project.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        
        <div className="mt-12 text-center">
          <Button 
            className="bg-accent text-white hover:bg-accent-dark rounded-full shadow-sm"
            onClick={() => window.open("https://scholar.google.com/citations?user=2XbaQy4AAAAJ&hl=en&oi=ao", "_blank")}
          >
            View All Projects
            <ArrowRight className="ml-2 h-4 w-4" />
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Projects;
