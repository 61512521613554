
import React from "react";

interface NavbarLogoProps {
  scrolled: boolean;
  isMobile: boolean;
}

const NavbarLogo = ({ scrolled, isMobile }: NavbarLogoProps) => {
  return (
    <a href="/" className="text-foreground font-display text-xl flex items-center gap-3">
      <span className={`p-2 rounded-lg ${scrolled ? "bg-gradient-to-br from-primary/10 to-accent/10" : "bg-white/10"}`}>
        <img 
          src="/lovable-uploads/ba7f970c-cb09-469e-9341-5ca1c833bbda.png" 
          alt="MIT Logo" 
          className="h-6 w-6"
        />
      </span>
      <div className="flex flex-col">
        <span className="font-bold text-gradient">Omar Costilla Reyes</span>
        {!isMobile && (
          <span className="text-xs text-foreground/70">AI Scientist & Digital Healthcare Expert</span>
        )}
      </div>
    </a>
  );
};

export default NavbarLogo;
