
import React from "react";
import { Brain, Microscope, Network, Workflow, Binary, Award } from "lucide-react";

const specializationAreas = [
  {
    title: "Neurosymbolic Programming",
    icon: <Brain className="h-6 w-6" />,
    description: "Developing systems that combine neural networks with symbolic reasoning for scientific discovery and theory generation from data. Leading the 'Understanding the World Through Code' project at MIT CSAIL."
  },
  {
    title: "Digital Phenotyping",
    icon: <Network className="h-6 w-6" />,
    description: "Creating mobile sensing frameworks to capture behavioral signals for mental health assessment. Specialized in anxiety and depression detection through passive monitoring technologies."
  },
  {
    title: "Neural Mechanisms",
    icon: <Workflow className="h-6 w-6" />,
    description: "Research on neural oscillations and intelligent behavior. Experience identifying cortical layers of the brain with machine learning techniques at the Picower Institute for Learning and Memory."
  },
  {
    title: "Gait Analysis",
    icon: <Binary className="h-6 w-6" />,
    description: "Pioneer in spatio-temporal gait pattern recognition for healthcare and security applications. Developed deep learning methods to analyze walking patterns for early detection of cognitive decline."
  },
  {
    title: "Scientific Discovery",
    icon: <Microscope className="h-6 w-6" />,
    description: "Creating AI systems that accelerate the scientific discovery process. Developing methodologies that generate testable hypotheses from data using neurosymbolic programming approaches."
  },
  {
    title: "International AI Leadership",
    icon: <Award className="h-6 w-6" />,
    description: "Founder of the Artificial Intelligence Latin American SumMIT initiative. Member of the Inter-American Committee on Science and Technology at the Organization of American States (OAS)."
  }
];

const SpecializedKnowledge = () => {
  return (
    <div className="py-8">
      <h3 className="text-2xl font-bold mb-8 text-center md:text-left">Specialized Knowledge</h3>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {specializationAreas.map((area, index) => (
          <div 
            key={index}
            className="bg-gradient-card p-6 rounded-2xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
            style={{ animationDelay: `${index * 150}ms` }}
          >
            <div className="flex items-start space-x-4">
              <div className="p-3 bg-gradient-to-br from-primary/10 to-primary/5 rounded-full text-primary">
                {area.icon}
              </div>
              <div className="space-y-2">
                <h4 className="text-lg font-bold">{area.title}</h4>
                <p className="text-foreground/70 text-sm">{area.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SpecializedKnowledge;
