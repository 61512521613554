
import React from "react";
import { Code, Trophy, CheckCircle } from "lucide-react";
import type { ServiceType } from "./ServiceCard";

interface ServiceDetailProps {
  service: ServiceType;
}

const ServiceDetail: React.FC<ServiceDetailProps> = ({ service }) => {
  return (
    <div className="glass-effect rounded-2xl p-8 relative overflow-hidden">
      {/* Decorative elements */}
      <div className="absolute -top-24 -right-24 w-48 h-48 rounded-full bg-primary/5 blur-3xl"></div>
      <div className="absolute -bottom-24 -left-24 w-48 h-48 rounded-full bg-accent/5 blur-3xl"></div>
      
      <div className="relative z-10">
        <div className="flex flex-col md:flex-row md:items-start gap-8 mb-10 pb-8">
          <div className="p-5 bg-gradient-to-br from-primary/10 to-primary/5 rounded-xl flex-shrink-0">
            {service.icon}
          </div>
          <div>
            <h4 className="text-2xl font-bold text-gradient mb-4">{service.title}</h4>
            <p className="text-foreground/70 text-lg leading-relaxed">{service.description}</p>
          </div>
        </div>
        
        <div className="space-y-8">
          <div>
            <h5 className="text-xl font-bold mb-8 flex items-center gap-2">
              <Code className="h-5 w-5 text-primary" />
              Technical Capabilities
            </h5>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {service.capabilities.map((capability, index) => (
                <div key={index} className="flex items-start gap-3 bg-gradient-to-br from-primary/5 to-transparent p-4 rounded-xl">
                  <span className="text-primary mt-1 flex-shrink-0">
                    <CheckCircle className="h-5 w-5" />
                  </span>
                  <span className="text-foreground/80">{capability}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetail;
